@import '../../../../index.scss';

.components__data-row {
  margin: 0;
  padding-top: 1.7rem;
  text-align: center;
  // padding-left: 1em;
  & ul {
    padding-left: 0px;
    text-align: left;

    @media (max-width: $breakpointMedium) {
      margin-bottom: 0px;
    }
  }
  & li {
    list-style: none;
    margin-top: 0.5rem;
  }
}

.temp-example-td {
  height: 55px;
}

.redText {
  color: $red;
}
