@import "../../../../index.scss";

.page-nav-tile {
  a {
    text-decoration: none;
    color: $fontDark;
  }

  .panel-container {
    display: flex;
    align-items: center;
    min-height: 200px;
  }

  .page-nav-tile__content {
    width: 100%;
    margin-right: 3px;
    & h2 {
    font-size: 28px;
    line-height: 48px;
    }
  }

  &:hover {
    a {
      text-decoration: none;
      color: inherit;
    }

    &:not(.--active) {
      color: $white;
      .panel-container {
        transition: $transition;
        background-color: var(--primary);
      }

      svg {
        polyline {
          transition: $transition;
          stroke: $white;
        }
      }
    }
  }

  &.--active {
    a {
      color: $white;
    }
    color: $white;
    .panel-container {
      background-color: var(--primary);
    }

    svg {
      polyline {
        stroke: $white;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: $spacingStandard;
  }

  @media (max-width: $breakpointLarge) {
    .panel-container {
      min-height: 180px;
    }

    .panel-container:last-child {
      margin-bottom: $spacingLarge;
    }
  }
}
