@import '../../../index.scss';

.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px $spacingStandard * 2 0px 24px;
  z-index: 999;

  .nav-bar-icons-wrapper {
    display: flex;
    align-items: center;
  }
  @media (max-width: $breakpointMedium) {
    .nav-bar-icons-wrapper {
      margin-left: auto;
    }
  }

  .nav-header-title {
    font-size: 36px;
    font-weight: 700;
    margin: 0px;
    color: $white;
  }

  .nav-bar-notification {
    position: relative;
    margin-right: $spacingLarge;

    &:hover {
      cursor: pointer;
    }
  }

  .nav-bar-notification-badge {
    position: absolute;
    top: 0px;
    left: 24px;
    margin-left: 10px;
    width: 25px;
    height: 25px;
    background-color: $red;
    border-radius: 15px;
    border: 1px solid $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-bar-notification-badge-text-bigger {
    width: 33px;
  }

  .nav-bar-notification-badge-text {
    font-size: 14px;
    letter-spacing: -0.2;
    line-height: 10px;
    color: $white;
    text-align: center;
    margin: 0px;
  }

  .nav-bar-profile {
    &:hover {
      cursor: pointer;
    }

    img {
      height: 48px;
      width: 48px;
      clip-path: circle(24px at center);
      border: 2px solid white;
      border-radius: 50%;
    }
  }

  .btn-toggle {
    cursor: pointer;
    min-width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .nav-header-logo {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      height: $logoHeight;
    }
  }

  .nav-header-org-name {
    color: $white;
    font-size: $fontSizeMD;
    padding: 0px $spacingLarge 0px $spacingLarge;
    margin: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    vertical-align: middle;
    overflow: hidden;
  }

  @media (max-width: $breakpointMedium) {
    padding: 0px $spacingSmall 0px $spacingSmall;

    .nav-header-title {
      display: none;
    }

    .btn-toggle {
      display: flex;
      padding-left: 0px;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }

    .nav-header-logo {
      cursor: pointer;
      margin-left: 5%;
    }

    .nav-header-org-name {
      display: none;
    }
  }

  @media (max-width: 448px) {
    .nav-header-logo {
      margin-left: 3%;
    }
    .nav-bar-notification {
      margin-right: 0.3rem;
    }
  }
}
