@import '../../../index.scss';

.components_facility-details-form {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  }

  .facility-details-form-text {
    font-size: $fontSizeSM;
    line-height: 19px;
    text-align: left;
    padding-top: $spacingSmall;
    color: $darkgrey;
  }

  .--semibold {
    font-weight: $semiBold;
  }

  form-control,
  input {
    background-color: #f2f2f2;

    &:focus {
      background-color: #f2f2f2;
    }
  }

  .input-field-row {
    max-width: 80%;
    margin-top: $spacingLarge;
    margin-bottom: $spacingStandard;
  }

  .input-field-half-row {
    width: 360px;
    margin-bottom: $spacingStandard;
    margin-right: $spacingVeryLarge;

    @media (max-width: $breakpointMedium) {
      width: 100%;
    }
  }

  .input-field-small-row {
    width: 160px;
    margin-bottom: $spacingStandard;
    margin-right: $spacingVeryLarge;

    @media (max-width: $breakpointMedium) {
      width: 100%;
    }
  }

  .facility-details-form-checkbox-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .facility-details-form-checkbox-wrapper > div {
    margin-top: $spacingSmall;
    margin-bottom: $spacingSmall;
  }

  .facility-details-form-dropdown-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media (max-width: $breakpointMedium) {
      flex-direction: column;
    }
  }

  .facility-details-form-courses-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media (max-width: $breakpointMedium) {
      width: 100%;
      flex-direction: column;
    }
  }

  .facility-details-form-remove-button-wrapper {
    @media (max-width: $breakpointMedium) {
      flex-direction: column;
    }
  }

  .--marginTop {
    margin-top: $spacingVeryLarge;
  }

  .facility-details-form-remove-button {
    display: flex;
    // width: 100px;
    height: 30px;
    border-style: unset;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    background-color: #f2f2f2;
    color: $resources;

    @media (max-width: $breakpointLarge) {
      display: none;
    }

    @media (max-width: $breakpointMedium) {
      display: none;
    }
  }

  .facility-details-form-remove-cross {
    display: none;

    @media (max-width: $breakpointLarge) {
      display: flex;
      justify-content: flex-end;
    }

    @media (max-width: $breakpointMedium) {
      display: flex;
      justify-content: flex-end;
    }
  }

  .facility-details-form-add-another-button {
    width: 200px;
    background-color: $white;
    color: var(--primary);
    margin-top: $spacingLarge;

    &:hover {
      background-color: var(--primary);
      color: $white;
      transition: $transition;
    }
  }

  .facility-details-form-courses {
    background-color: #f2f2f2;
    padding: $spacingStandard;
  }

  .wrapper {
    width: 95%;
    background-color: #f2f2f2;
  }

  .facility-details-form-courses-body {
    width: 100%;
    background-color: $white;
    padding: 0px;
  }

  .input-text-field-half-row {
    width: 260px;
    margin-bottom: $spacingStandard;
    margin-right: $spacingVeryLarge;

    @media (max-width: $breakpointMedium) {
      width: 100%;
    }
  }
}
