@import "../../index.scss";

.create-facility-divider {
  height: 1px;
  width: 100%;
  background-color: $lightGrey;
  margin-top: $spacingStandard;
  margin-bottom: $spacingStandard;
}

.create-facility-button {
  align-self: flex-end;
}

.padding-left {
  padding-left: 15px!important;
  padding-right: 15px!important;

}