@import '../../../../index.scss';

.liner-progress-card {
  width: 100%;
  background: $taupe;
  padding: 14px 22px;
  margin-bottom: $spacingStandard;
  border-radius: panelBorderRadius;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.linear-progress-card-incomplete {
  margin-right: $spacingStandard;
  align-self: center;
  margin-top: 5px;
  padding-left: 12px;
}

.linear-progress-card-meta {
  display: flex;
  flex: 1;
  padding: 0 $spacingLarge 0 $spacingLarge;
}
.linear-progress-card-meta-progress {
  flex: 1;
}

.liner-progresss-card___img-wrapper {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    align-self: center;
    max-height: 88%;
    width: auto;
  }
}

.linear-progress-title {
  margin-bottom: -8px;
  &.--primary {
    color: var(--primary);
  }
  &.--secondary {
    color: $resources;
  }
  &.--tertiary {
    color: $community;
  }
  &.--approach {
    color: $approach;
  }
  &.--baseline {
    color: $baseline;
  }
}

.linear-progress-link {
  align-self: center;
  min-width: $mediumButtonWidth;
  border: 0;
  &:hover {
    color: $white;
  }
}

@media screen and (max-width: $breakpointSmall),
  (min-width: $breakpointMedium) and (max-width: $breakpointLarge) {
  .liner-progress-card {
    flex-direction: column;
  }

  .liner-progresss-card___img-wrapper {
    align-self: center;
  }

  .linear-progress-card-meta {
    width: 100%;
    margin-bottom: $spacingSmall;
  }

  .linear-progress-card-incomplete {
    margin-top: 10px;
    margin-right: 0;
  }

  .linear-progress-link {
    width: 100%;
  }
}
