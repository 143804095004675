@import "../../index.scss";

.component__profile-details {

  .profile-page-profile-details-form {
    margin-top: $spacingVeryLarge;
    width: 100%;

    @media (max-width: $breakpointSmall) {
      margin-top: $spacingStandard;
    }
  }

  .profile-details-roles-card{
    margin-top: $spacingVeryLarge;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 16px;
    padding: 24px;
    background-color: $white;
  }

  .profile-details-roles-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .profile-details-roles-header-title{
    font-size: 20px;
    color: $darkgrey;
    font-weight: $semiBold;
    line-height: 24px;
    letter-spacing: -0.29px;
    margin: 0px;
    padding-bottom: $spacingSmall;
  }

  .profile-details-roles-description {
    font-size: $fontSizeMD;
    color: $darkgrey;
    line-height: 21px;
    letter-spacing: -0.29px;
    margin: 0px;
  }

  .profile-details-roles-add-button {
    width: 114px;
    height: 44px;
    background-color: transparent;
    color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--primary);
    color: $white;
    }
  }

  .profile-details-roles-delete-button {
    outline: none;
    background-color: transparent;
    border-color: transparent;
    color: $red;
    font-size: fontSizeSM;
    font-weight: $semiBold;

    @media (max-width: $breakpointSmall) {
      padding: 0px;
    }
  }

  .profile-details-roles-table-header {
    width: 100%;
    display: flex;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    margin-top: $spacingLarge;
    border-style: hidden;
  }

  table tr {
    background-color: #F7F7F7;
    border: 1px solid $lightSilver;
    border-radius: 8px;
    height: 56px;
  }

  table th,
  table td {
    padding: .625em;
    text-align: center;
  }

  table th {
    font-size: $fontSizeSM;
    color: #000000;
    font-weight: $semiBold;
  }

  @media (max-width: $breakpointSmall) {
    table {
      border: 0;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      display: block;
      margin-bottom: .625em;
      height: unset;
      background-color: #F7F7F7;
      padding: .35em;
    }

    table td {
      border-bottom: 1px solid $lightSilver;
      display: block;
      font-size: $fontSizeSM;
      color: $black;
      line-height: 19px;
      letter-spacing: -0.23px;
      margin: 0px;
      text-align: right;
    }

    table td::before {
      content: attr(data-label);
      float: left;
      font-size: $fontSizeSM;
      color: #000000;
      font-weight: $semiBold;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
}

.profile-add-role-modal-input-field-rows {
  margin-top: $spacingLarge;
  margin-bottom: $spacingStandard;

  @media (max-width: $breakpointMedium) {
    width: 100%;
  }
}


.components__modal {
  .modal-content {
    .modal-container {
      overflow-y: visible;
    }
  }
}

.profile-add-role-modal-wrapper {
  @media (max-width: $breakpointMedium) {
    min-width: 250px;
  }
}

