@import "../../index.scss";

.component__profile-facility-team {
  .profile-facility-team-roles-card {
    margin-top: $spacingVeryLarge;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 16px;
    padding: 24px;
    background-color: $white;
  }
  .facility-profile-team-header {
    word-break: break-all;
  }

  .profile-facility-team-table {
    td {
      word-break: break-word;
    }
  }

  .profile-facility-team-roles-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .profile-facility-team-roles-header-title {
    font-size: 20px;
    color: $darkgrey;
    font-weight: $semiBold;
    line-height: 24px;
    letter-spacing: -0.29px;
    margin: 0px;
    padding-bottom: $spacingSmall;
  }

  .profile-facility-team-roles-description {
    font-size: $fontSizeMD;
    color: $darkgrey;
    line-height: 21px;
    letter-spacing: -0.29px;
    margin: 0px;
    padding-top: $spacingStandard;
  }

  .profile-facility-team-roles-delete-button {
    outline: none;
    background-color: transparent;
    border-color: transparent;
    font-size: fontSizeSM;
    font-weight: $semiBold;

    @media (max-width: $breakpointSmall) {
      padding: 0px;
    }
  }

  .--green {
    color: var(--primary);
  }

  .--red {
    color: $red;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    margin-top: $spacingLarge;
    border-style: hidden;
  }

  table tr {
    background-color: #f7f7f7;
    border: 1px solid $lightSilver;
    border-radius: 8px;
    height: 56px;
  }

  table th,
  table td {
    padding: 0.625em;
    text-align: center;
  }

  table th {
    font-size: $fontSizeSM;
    color: #000000;
    font-weight: $semiBold;
  }

  @media (max-width: $breakpointSmall) {
    table {
      border: 0;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      display: block;
      margin-bottom: 0.625em;
      height: unset;
      background-color: #f7f7f7;
      padding: 0.35em;
    }

    table td {
      border-bottom: 1px solid $lightSilver;
      display: block;
      font-size: $fontSizeSM;
      color: $black;
      line-height: 19px;
      letter-spacing: -0.23px;
      margin: 0px;
      text-align: right;
    }

    table td::before {
      content: attr(data-label);
      float: left;
      font-size: $fontSizeSM;
      color: #000000;
      font-weight: $semiBold;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
}

.input-field-row {
  width: 100%;
  margin-top: $spacingLarge;
  margin-bottom: $spacingStandard;
}

.components__modal {
  .input-field-email-row {
    width: 40%;
    margin-top: $spacingLarge;
    margin-bottom: $spacingStandard;
    margin-right: $spacingStandard * 3;

    @media (max-width: $breakpointMedium) {
      width: 100%;
      margin-right: 0px;
    }
  }

  .profile-add-user-modal-input-field-rows {
    width: 35%;

    @media (max-width: $breakpointMedium) {
      width: 100%;
    }
  }

  .profile-facility-team-invite-modal {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: $breakpointMedium) {
      flex-direction: column;
    }
  }

  .profile-facility-team-modal-button {
    width: 200px;
    margin-top: $spacingSmall;
    align-self: flex-end;
  }

  .profile-facility-team-modal-alert {
    margin-top: $spacingStandard;
  }

  .profile-facility-team-modal-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
