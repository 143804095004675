@import "../../../index.scss";

.pages__forgotten-password-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  max-width: 500px;
  padding: $spacingStandard;
  margin-bottom: 150px;
  z-index: 2;

  @media (min-width: $breakpointSmall) {
    margin-bottom: 0px;
  }

  img {
    height: $logoHeight;
  }

  .forgotten-password-title {
    position: absolute;
    top: $spacingLarge;
    left: $spacingLarge;
  }

  .forgotten-password-card {
    width: 100%;
    min-height: 465px;
    top: $spacingVeryLarge;

    @media (min-width: $breakpointSmall) {
      width: 600px;
      top: 0px;
    }
  }

  .cancel-password-button {
    margin-top: $spacingStandard;
    color: $red;
    border-color: $white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
  }
}
