@import '../../../../index.scss';

.document-library-card {
  width: 17.77%;
  height: 210px;
  border-radius: 16px;
  box-shadow: $boxShadow;

  button {
    padding: 0px 0.25rem;
  }

  flex-direction: column;
  min-width: 280px;
  align-items: center;
  background-color: $white;
  padding: 0 !important;
  height: 100%;
  margin-right: $spacingVeryLarge;
  margin-bottom: $spacingSmall;

  @media (max-width: $breakpointLarge) {
    min-width: 35%;
    margin: 0.5rem 0px;
    flex: 1;
  }

  .--primary {
    border-color: var(--primary);
    color: $black;
  }

  .--secondary {
    border-color: $resources;
    color: $black;
  }

  .--tertiary {
    border-color: $community;
    color: $black;
  }
}
.document-library-card-button {
  margin-top: 0;
  padding-right: 2px;
  padding-left: 2px;
  width: 100%;
  height: auto;
}
.document-library-card-button-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.document-library-card-content {
  margin-top: 0.5rem;
  padding-inline: 0.5rem;
  text-align: left;

  @media (max-width: $breakpointSmall) {
    img {
      display: none;
    }
  }
}
.document-library-card-content img {
  width: 30px;
  margin-right: $spacingSmall;
}
.document-library-card-content-title {
  font-size: $fontSizeMD;
  word-wrap: anywhere;
}
.document-library-card-content-count {
  font-size: $fontSizeMD;
}
.document-library-card-pointer {
  display: flex;
  align-items: center;
  height: auto;
  flex: 1;
  justify-content: flex-end;
}

.document-library-card-button {
  background-color: $white;

  &:hover {
    color: $white;
    transition: $transition;
  }
}

.document-library-card-pointer {
  svg {
    height: 24px;
  }
}
