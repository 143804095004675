@import "../../index.scss";

.pages__profile-page {
  @media (max-width: $breakpointMedium) {
    padding: 0px;
  }

  .profile-page-header {
    width: 100%;
    height: 47px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .profile-page-header-title {
    font-size: 36px;
    font-weight: $bold;
    color: $darkgrey;
    margin: 0px;

    @media (max-width: $breakpointMedium) {
      font-size: $fontSizeLG;
      padding-bottom: $spacingReallySmall;
    }
  }

  .profile-save-button {
    outline: none;
    width: 114px;
  }

  .profile-tabswitch {
    padding-top: 40px;

    @media (max-width: $breakpointSmall) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: unset;
      margin-bottom: 0px;

      .tabswitch-separator {
        height: 0px;
      }

      .tabswitch-item-text {
        margin-bottom: $spacingSmall;
      }
    }
  }
}
