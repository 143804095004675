@import "../../../index.scss";

.components__new_password_form {
  .input-field-row {
    margin-bottom: $spacingStandard;
    label {
      color: $black;
    }

    .alert {
      white-space: pre-line;
    }
  }

  .reset-password-button {
    width: 200px;
    z-index: 99;
  }

  .reset-password-button {
    margin-bottom: $spacingStandard;
  }

  .components__new_password_form__password-details {
    margin: $spacingLarge 0;
  }
}
