@import "../../../../index.scss";

.best-practices {
  .accordion-card-body-wrapper {
    background-color: transparent;
  }
}

.best-practices-wrapper__breadcrumbs {
  text-transform: capitalize;
}

.question-save-button {
  width: 114px;
}

.section-container {
  width: 100%;
  height: 100vh;
  padding: 0px $spacingVeryLarge 0px $spacingVeryLarge;

  @media (max-width: $breakpointMedium) {
    padding: 0px $spacingStandard 0px $spacingStandard;
  }
}

.light-font {
  font-weight: normal;
}
