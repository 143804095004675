@import "../../../../index.scss";

.linear-progress-bar {
  display: flex;
  align-items: center;
}
.linear-progress-bar-container {
  position: relative;
  width: 100%;
  margin-right: $spacingStandard;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;

  @media only screen and (min-width: 768px) {
			margin-top: 1rem;
			margin-bottom: 2rem;
		}
}

.linear-progress-bar-meter {
  height: $spacingSmall;
  border-radius: 20px;
  position: absolute;
  top: 0;
}

.linear-progress-bar-background {
  width: 100%;
  opacity: 0.2;
  border-radius: 20px;
  height: $spacingSmall;
}

.linear-progress-bar-subtitle {
  top: 0.6rem;
  position: absolute;
  color: $darkgrey;
  font-size: $fontSizeXS;
  font-family: $main;
  min-width: max-content;
}

.linear-progress-bar-percentage {
  margin: 0;
}
