@import '../../../index.scss';
.dashboard-page-container {
  padding-right: 1em;
  max-width: 1440px;
}
.dashboard-container-row {
  display: flex;
  margin-top: $spacingLarge;
  justify-content: space-between;

  @media (max-width: 1360px) {
    flex-direction: column;
  }
}

.dashboard-container-tiles-row {
  display: flex;
  width: 100%;
  margin-top: 3em;
  & .dashboard-container-tile {
    flex: 1;
    border-radius: 8px;
    background-color: var(--primary);
    margin-right: 3em;
    & .panel-container {
      box-shadow: none;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  & .dashboard-social-media-tile {
    flex: 1;
    border-radius: 8px;
    background-color: var(--primary);
    margin-right: 3em;
    width: 100%;
    & .panel-container {
      box-shadow: $boxShadow;
    }
    &:last-of-type {
      margin-right: 0;
    }
    & .panel-container {
      height: 100%;
      width: 100%;
    }
  }
  @media (max-width: 945px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .dashboard-container-tile,
    & .dashboard-social-media-tile {
      flex: 1 1;
      margin-top: 1em;
      margin-right: 0;
      min-width: 360px;
      & .panel-container {
        box-shadow: $boxShadow;
      }
    }
  }
  @media (max-width: 375px) {
    & .dashboard-container-tile,
    & .dashboard-social-media-tile {
      min-width: 345px;
      margin-right: -12px;
      &:last-of-type {
        margin-right: -12px;
      }
    }
  }
}

.dashboard-to-highlight-button {
  background-color: $white;
  color: var(--primary);
  font-size: 13px;
  height: 39px;
  width: 190px;
  padding: 0px;
  border: 2px solid var(--primary);
  border-radius: 4px;

  &:hover {
    color: $white;
    background-color: var(--primary);
    transition: $transition;
  }
}
