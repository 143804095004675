@import "../../../index.scss";


.support-pannel--title {
    font-size: $fontSizeLG;
    font-weight: $semiBold;
    line-height: 29px;
    margin-bottom: 1em;
}

.support-planning-description p{
    font-size: $fontSizeSM;
    line-height: 21px;
}
.links-field-divider {
    height: 0.5px;
    background-color: $lightGrey;
    margin-top:16px;
}

.support-planning__links-container {
     background-color: $taupe;
     padding:20px;
     & .support-planning__links-title {
          color: $darkgrey;
        font-size: $fontSizeMD;
        letter-spacing: 0;
        line-height: 21px;
        margin-bottom: 8px;
     }
     &  .support-planning__panel--downloads {
         display: flex;
         flex-direction: column;
          p {
            margin-top: 16px;
            margin-bottom: 0;
            }
        .download-resources-anchor {
            color: $hyperLink;
            font-size: $fontSizeMD;
            font-weight: $semiBold;
            letter-spacing: 0;
            line-height: 21px;
            align-items: normal;
            background-color: rgba(0,0,0,0);
            border-style: none;
            box-sizing: content-box;
            cursor: pointer;
            text-align: start;
        }
    }
}