@import "../../index.scss";

.pages__facility-profile-page {
  width: 100%;

  .facility-profile-page-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .facility-profile-page-header-title {
    font-size: 36px;
    line-height: 42px;
    font-weight: $bold;
    color: $darkgrey;
    margin: 0px;

    @media (max-width: $breakpointMedium) {
      font-size: $fontSizeLG;
    }
  }

  .facility-profile-save-button {
    outline: none;
    width: 114px;
  }

  .facility-profile-tabswitch {
    padding-top: 40px;
  }
}
