@import "../../../index.scss";

.best-practices__panel-title {
  margin-bottom: $spacingStandard;
}

.best-practices__progress-container {
  margin-bottom: $spacingLarge;
}
.best-practices__panel-description {
  font: $body;
  letter-spacing: $bodyLetterSpacing;
}
