@import "../../../../index.scss";

.dynamic-links__subsection-link {
  text-transform: capitalize;
  color: $black !important;

  .pro-arrow-wrapper {
    display: none !important;
  }
  .subsection-indicator{
    color: var(--primary);
    font-weight: 600;
    font-size: $fontSizeLG;
    margin: 0px;
    padding: 0px;
    margin-right: 3px;
  }
}

.review-menu {
  ul {
    padding-bottom: 0 !important;
  }

  .sidebar-submenu--item {
    border-bottom: 0 !important;
  }

  .sub-navigation-menu {
    .pro-item-content {
      text-overflow: unset !important;
      overflow: unset !important;
      white-space: unset !important;
      font-size: 14px;
      padding: 0px;
      margin: 0px;
      line-height: 18px;
    }
  }
}
.review-menu::after {
  content: "";
  position: absolute;
  left: 5%;
  height: 1px;
  width: 90%;
  padding-bottom: 0px;
  border-bottom: 1px solid var(--primary);
}

.pro-sidebar .pro-menu .sidebar-nav--selectedItem:after {
  border-bottom: 0 !important;
}

.pro-sidebar .pro-menu {
  .active {
    color: var(--primary);
  }

  .pro-arrow-wrapper {
    width: 20px;
    height: 20px;
    right: 13px;
    top: 15px;
  }
}
