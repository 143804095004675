.components__external-link-button {
  a {
    text-decoration: none;
    color: unset;
    &:hover {
      text-decoration: none;
      color: unset;
    }
  }
}
