@import '../../../index.scss';

.badge-success {
  font-family: Ariel, sans-serif;
  font-size: 16px !important;
  background: #f7f7f7 !important;
  color: #009150 !important;
  min-width: 100px;
  max-width: 132px !important;
  height: 32px;
  padding: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
tr.overall-total-row {
  background: #f7f7f7 !important;
  td,
  th {
    color: #1d1d1d;
  }
}

table {
  tr {
    border: 1px solid #d8d8d8;
    td.overall-format-total-row {
      color: #009150;
      font-family: Ariel, sans-serif;
      font-weight: 600;
      border-left: 1px solid #e6e6e5;
    }
  }
}

.row-question-field-calculating-question {
  margin: 0px;
  justify-content: space-between;

  .dropdown-toggle-value-default {
    padding-left: 14px;
    padding-right: 7px;
  }

  @media (max-width: $breakpointMedium) {
    width: 100%;
  }

  & .components__form-text-input-field .form-inner-wrapper .form-text-input-units {
    margin-bottom: 0px;
    color: $lightGrey;
    font-size: $fontSizeSM;
    letter-spacing: 0;
    line-height: 19px;
  }

  .row-question-unit-selector {
    margin-left: $spacingStandard;
  }

  .row-question__dropdown-container {
    .row-question__dropdown {
      min-width: max-content;
      align-items: center;
      p {
        margin: 0;
        padding-right: 10px;
        font-weight: $normal;
        line-height: 50px;
      }
    }
  }

  .input-field-row-text {
    padding-bottom: $spacingStandard * 2;
    width: 60%;
  }

  .calculating-question-title {
    margin: 0px 0px 0px 10px;
    text-align: left;
  }
}

.row-question-field-calculating-question
  .row-question-input-square
  .components__form-text-input-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  & label {
    margin-right: 8px;
  }
  & .form-inner-wrapper {
    border-bottom: none;
  }
  & input {
    border: 1px solid #969896;
  }
}

.row-question-field-calculating-question .redText input {
  color: $red;
}
.row-question-field-calculating-question .form-inner-wrapper {
  width: 100% !important;
  border: none !important;
}

.row-question-field-calculating-question,
.calculatingQuestionContainer {
  input {
    width: 100% !important;
    background-color: #fff !important;
    display: block !important;
    width: inherit !important;
    border: unset;
    outline: none !important;
    padding: 0 10px !important;
    height: 35px !important;
  }

  input:placeholder-shown {
    text-overflow: ellipsis;
    color: $mutedGrey;
    font-style: italic;
    font-size: 14px;
  }

  .dropdown-toggle {
    min-width: 10rem;
  }
}
.row-question-field-calculating-question .form-text-input-field-number {
  display: none !important;
}

table.calculatingQuestionContainer td,
table.calculatingQuestionContainer th {
  padding: 0 15px !important;
  border: solid 1px #bbb !important;
  border-right: solid 1px #eee !important;
  vertical-align: middle !important;
  font-size: 14px !important;
}

table.calculatingQuestionContainer table td {
  padding: 0 !important;
}

table.calculatingQuestionContainer table {
  width: 100%;
  @media (max-width: $breakpointSmall) {
    display: block;
    border: none;

    tr {
      border: none !important;

      td {
        text-align: center;
        > * {
          margin-inline: 0.4rem;
        }
      }

      :nth-child(3) {
        span {
          min-width: 3rem;
        }
      }

      :nth-child(4) {
        button {
          padding: 0.2rem;
          margin-left: 0.4rem;
          margin-right: -0.8rem;
        }
      }
    }

    td:before {
      content: attr(data-th);
      display: block;
    }
  }
}

table.no-border td {
  border: none !important;
}

table.calculatingQuestionContainer td.headerColumn {
  padding: 0 15px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.table-cell-desktop {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table-cell-mobile {
  width: 100%;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.inner-table-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: $breakpointMedium) {
  .table-cell-desktop {
    display: none;
  }

  .table-cell-mobile {
    display: flex;
  }

  .inner-table-cell {
    flex-direction: column;
    align-items: start;
  }
}

.arrow-left-mobile {
  transform: rotate(90deg);
}
