@import "../../../../index.scss";

.page-nav-title__content {
    width: 100%;
    margin-right: 12px;
    & .page-nav-title__text {
        font-size: 18px;
        font-weight: $semiBold;
        letter-spacing: 0;
        margin-bottom: 4px;
        line-height: 29px;
      }
    & .page-nav-description__text {
        font-size: 14px;
        letter-spacing: $h6LetterSpacing;
        line-height: 19px;
        margin-bottom: 8px;
      }

      .page-nav-sublink {
        font-size: 14px;
      }
  }
.page-nav-sublink__hidden {
  visibility: hidden;
}

.page-nav-right-align{
  display: flex;
  justify-content: flex-end
}

.dashboard-title-button__wrapper {
  align-items: center;
  margin-top: 12px;
}