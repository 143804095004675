@import '../../../index.scss';

.components__form-dropdown-field {
  .dropdown {
    &:hover {
      cursor: pointer;
    }
  }
  .dropdown-toggle {
    margin: 0px;
    padding: 0px;
    max-width: 340px;
    height: 40px;
    display: flex;
    border-radius: 2px;

    @media (max-width: $breakpointMedium) {
      width: 100%;
    }
  }

  .dropdown-toggle-value-default {
    width: 100%;
    line-height: 28px;
    padding-left: $spacingStandard;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .dropdown-toggle-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: $spacingSmall;
  }

  .dropdown-menu.show {
    transform: translate3d(0px, 52px, 0px) !important;
  }

  .dropdown-menu {
    border-color: #d3d3d3;
    border-radius: 2px;
    padding: 0px;
    @media (max-width: $breakpointMedium) {
      width: 100%;
    }
  }

  .dropdown-item {
    min-height: 40px;
    font-size: $fontSizeSM;
    font-family: $main;
    display: flex;
    align-items: center;
    white-space: unset;
  }

  .dropdown-divider {
    margin: 0px $spacingSmall 0px $spacingSmall;
    border-width: 1px;
  }

  .error {
    border: 1px solid $validationFailureBorderColour;
  }
}

.components__form-dropdown-light {
  color: $white;

  .dropdown-toggle {
    border: 1px solid #d3d3d3;
    background-color: $white;

    &:hover {
      border: 1px solid var(--primary-dropdown);
    }

    &:after {
      display: none;
      border: 1px solid var(--primary-dropdown);
    }

    > p {
      height: 40px;
      line-height: 36px;
    }
  }

  .dropdown-open {
    border: 1px solid var(--primary-dropdown);
  }

  .dropdown-toggle-value-default {
    color: $mutedGrey;
  }

  .dropdown-toggle-value {
    color: $black;
  }

  .dropdown-menu {
    color: $mutedGrey;
    background-color: $white;
    border-color: #d3d3d3;
  }

  .dropdown-item {
    color: $black;
    font-size: $fontSizeSM;
    font-family: $main;

    &:hover {
      background-color: $white;
      color: var(--primary-dropdown);
    }
  }

  .dropdown-divider {
    margin: 0px $spacingSmall 0px $spacingSmall;
    border-width: 1px;
    border-color: #d3d3d3;
  }
}

.components__form-dropdown-dark {
  color: $white;

  .dropdown-toggle {
    border: 1px solid #d3d3d3;
    background-color: $black;

    &:hover {
      border: 1px solid var(--primary-dropdown);
    }

    &:after {
      display: none;
      border: 1px solid var(--primary-dropdown);
    }
  }

  .dropdown-open {
    border: 1px solid var(--primary-dropdown);
  }

  .dropdown-toggle-value-default {
    color: $mutedGrey;
  }

  .dropdown-toggle-value {
    color: $white;
  }

  .dropdown-menu {
    color: $mutedGrey;
    background-color: $black;
    border-color: #d3d3d3;
  }

  .dropdown-item {
    color: $white;
    font-size: $fontSizeSM;
    font-family: $main;

    &:hover {
      background-color: $black;
      color: var(--primary-dropdown);
    }
  }

  .dropdown-divider {
    margin: 0px $spacingSmall 0px $spacingSmall;
    border-width: 1px;
    border-color: #d3d3d3;
  }
}
