@import "../../../index.scss";

.component__toggle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0px $spacingSmall 0px 0px;
  .btn-toggle {
    padding: 0;
    position: relative;
    border: none;
    height: 32px;
    width: 48px;
    border-radius: 22px;
    background: $lightGrey;
    outline: none;
  }

  .handle {
    position: absolute;
    top: 3px;
    left: 4px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border-left: 12;
    background: white;
    transition: left 0.25s;
  }

  .active {
    transition: background-color 0.25s;
    background-color: var(--primary);

    .handle {
      left: 18px;
      transition: left 0.25s;
    }
  }

  .toggle-title {
    font-size: $fontSizeSM;
    color: $darkgrey;
    letter-spacing: -0.23;
    line-height: 19px;
    text-align: left;
    margin: 0px;
  }
}
