@import "../../../index.scss";

.pages__registration-link-sent-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  max-width: 500px;
  padding: $spacingStandard;
  margin-bottom: 150px;

  @media (min-width: $breakpointSmall) {
    margin-bottom: 0px;
  }

  img {
    height: $logoHeight;
  }

  .registration-link-sent-title {
    position: absolute;
    top: $spacingLarge;
    left: $spacingLarge;
  }

  .registration-link-sent-card {
    width: 100%;
    min-height: 465px;
    top: $spacingVeryLarge;
    background-color: $black;

    @media (min-width: $breakpointSmall) {
      width: 600px;
      top: 0px;
    }
  }

  .registration-link-sent-card-title {
    color: $white;
  }

  .registration-link-sent-description {
    padding-top: $spacingLarge;
    line-height: 19px;
    color: $white;
    font-size: $fontSizeSM;
    margin: 0px;
  }

  .registration-link-sent-cancel-button {
    width: 220px;
    margin-top: $spacingVeryLarge;
  }

  .registration-link-sent-resend-button {
    width: 220px;
    background-color: transparent;
    border-color: transparent;
    color: var(--primary);
    outline: none;
    margin-top: $spacingStandard;
  }
}
