@import "./index";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700;800&display=swap");

body {
  margin: 0;
  font-family: "Raleway", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Raleway", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

h1 {
  font: $h1;
  letter-spacing: $h1LetterSpacing;
}
h2 {
  font: $h2;
  letter-spacing: $h2LetterSpacing;
}
h3 {
  font: $h3;
  letter-spacing: $h3LetterSpacing;
}

h4 {
  font: $h4;
  letter-spacing: $h4LetterSpacing;
}

h5 {
  font: $h5;
  letter-spacing: $h5LetterSpacing;
}

h6 {
  font: $h6;
  letter-spacing: $h6LetterSpacing;
}

p,
li {
  font: $body;
  letter-spacing: $bodyLetterSpacing;
}

.page {
  width: 100%;
  min-height: calc(100vh - 172px);
  &::before {
    z-index: -1;
    width: 100vw;
    height: 100%;
    content: "";
    background-size: unset;
    background-repeat: no-repeat;
    background-position: top right;
    position: fixed;
    background-color: $taupe;
  }
}

.img-tour {
   &::before {
    background-image: url("assets/ring-tournaments.svg");
   }
}

.img-facility {
   &::before {
    background-image: url("assets/ring.svg");
   }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .--dark {
    color: $fontDark;
  }
}

.page-header {
  padding: $spacingVeryLarge $spacingVeryLarge $spacingVeryLarge
    $spacingVeryLarge;

  @media (max-width: $breakpointMedium) {
    padding: $spacingStandard $spacingStandard $spacingStandard $spacingStandard;
  }

  .--dark {
    color: $fontDark;
  }

  .--bold {
    font-weight: $bold;
  }
}

.page-title {
  font-size: 36px;
  font-weight: 700;
  color: #525352;
  margin-bottom: $spacingLarge;
}

.review-page-title {
  color: #525352;
  font-family: Raleway;
  font-size: 17px;
  font-weight: 800;
  letter-spacing: 0;

  &.--uppercase {
    text-transform: uppercase;
  }

  &.--capitalize {
    text-transform: capitalize;
  }
}

.review-page-section-title {
  color: #525352;
  font-size: 28px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 32px;
}

.review-page-alert {
  color: $red;
  font-size: $fontSizeSM;
  letter-spacing: 0;
  line-height: 18px;
}

.breadcrumb-link {
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.italic {
  font-style: italic;
}
