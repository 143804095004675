@import '../../../index.scss';

.document-library-your-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: $breakpointLarge) {
    column-gap: 1rem;
  }
}

.component__document-library {
  .document-library-your-documents-latest-card {
    margin-top: $spacingVeryLarge;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
    border-radius: 16px;
    padding: 24px;
    background-color: $white;

    table td {
      border: 0px;
    }

    table th {
      border: 0px;
    }

    table {
      th:last-of-type {
        width: 100px;
      }
    }
  }

  .document-library-your-documents-latest-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .document-library-your-documents-latest-header-title {
    font-size: 24px;
    color: $darkgrey;
    font-weight: $semiBold;
    line-height: 24px;
    letter-spacing: -0.29px;
    margin: 0px;
    padding-bottom: $spacingSmall;
  }

  .document-library-your-documents-latest-description {
    font-size: $fontSizeMD;
    color: $darkgrey;
    line-height: 21px;
    letter-spacing: -0.29px;
    margin: 0px;
    padding-top: $spacingStandard;
  }

  .document-library-your-documents-latest-delete-button {
    outline: none;
    background-color: transparent;
    border-color: transparent;
    font-size: fontSizeSM;
    font-weight: $semiBold;

    @media (max-width: $breakpointSmall) {
      padding: 0px;
    }
  }

  .document-library-your-documents-latest-download-button {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $hyperLink;
    font-size: $fontSizeSM;
    font-weight: $semiBold;
    letter-spacing: 0;
    line-height: 21px;
    align-items: normal;
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    box-sizing: content-box;
    cursor: pointer;
    text-align: start;
    &:hover {
      text-decoration: none;
    }
  }

  .--green {
    color: var(--primary);
  }

  .--red {
    color: $red;
  }

  .--blue {
    color: $hyperLink;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    margin-top: $spacingLarge;
    border-style: hidden;
  }

  table tr {
    background-color: #f7f7f7;
    border: 1px solid $lightSilver;
    border-radius: 8px;
    height: 56px;
  }

  table th,
  table td {
    padding: 0 20px;
    text-align: left;
  }

  table th {
    font-size: $fontSizeSM;
    color: #000000;
    font-weight: $semiBold;
  }

  @media (max-width: $breakpointSmall) {
    table {
      border: 0;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      display: block;
      margin-bottom: 0.625em;
      height: unset;
      background-color: #f7f7f7;
      padding: 0.35em;
    }

    table td {
      border-bottom: 1px solid $lightSilver;
      display: flex;
      justify-content: space-between;
      font-size: $fontSizeSM;
      color: $black;
      line-height: 19px;
      letter-spacing: -0.23px;
      margin: 0px;
      text-align: right;
      border: none !important;
      border-bottom: 1px solid $lightSilver !important;
      padding: 1rem 0.5rem !important;

      :nth-child(1) {
        text-align: right;
      }
    }

    table td::before {
      content: attr(data-label);
      float: left;
      text-align: left;
      font-size: $fontSizeSM;
      color: #000000;
      font-weight: $semiBold;
      padding-right: 1rem;
    }

    table td:last-child {
      border-bottom: 0 !important;
      text-align: left;
    }
  }
}
.document-library-process {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  button {
    font-weight: 600;
    background: none;
    border: none;

    &#edit {
      color: var(--primary);
    }

    &#delete {
      color: $red;
    }
  }
  & .document-library-process-separator {
    height: 23px;
    width: 1px;
    background-color: $lightSilver;
    color: var(--primary);
    margin: 0px 5px 0px 5px;

    @media (max-width: $breakpointMedium) {
      height: unset;
      width: unset;
    }
  }

  @media (max-width: $breakpointMedium) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.input-field-row {
  width: 100%;
  margin-top: $spacingLarge;
  margin-bottom: $spacingStandard;
}

.components__modal {
  .document-library-upload-button {
    background: $white;
    color: var(--primary);
    width: 200px;

    p {
      margin-bottom: 0;
      font-weight: $bold;
    }
  }

  .document-library-your-documents-latest-selected-file {
    display: flex;
    flex-direction: row;
    background-color: $taupe;
    padding: $spacingStandard;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .document-library-your-documents-latest-delete-button {
    outline: none;
    background-color: transparent;
    border-color: transparent;
    font-size: fontSizeSM;
    font-weight: $semiBold;
    padding: 0;

    @media (max-width: $breakpointSmall) {
      padding: 0px;
    }
  }

  .document-library-upload-modal {
    display: flex;
    flex-direction: column;

    @media (max-width: $breakpointMedium) {
      flex-direction: column;
    }
  }

  .document-library-upload-modal-button {
    width: 200px;
    margin-top: $spacingSmall;
    align-self: flex-end;
  }

  .document-library-upload-modal-note {
    font-weight: $bold;
    margin-right: $spacingSmall;
  }

  .document-library-upload-modal-alert {
    margin-top: $spacingStandard;
  }

  .document-library-upload-modal-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .--red {
    color: $red;
  }
}
