@import "../../../index.scss";

.pages__new-password-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  max-width: 500px;
  padding: $spacingStandard;
  margin-bottom: 150px;

  @media (min-width: $breakpointSmall) {
    margin-bottom: 0px;
  }

  img {
    height: $logoHeight;
  }

  .new-password-page-title {
    position: absolute;
    top: $spacingLarge;
    left: $spacingLarge;
  }

  .new-passowrd-card {
    width: 100%;
    min-height: 465px;
    top: $spacingVeryLarge;

    @media (min-width: $breakpointSmall) {
      width: 600px;
      top: 0px;
    }
  }
}
