@import '../../../../index.scss';

.form-modal-field-divider {
  height: 1px;
  background-color: #70707050;
  margin-top: 22px;
  margin-bottom: 16px;
}

.no_topMargin {
  margin-top: 0px;
}
.form-title {
  font-family: Raleway;
  font-size: $fontSizeSM;
  font-weight: $semiBold;
}
.form-title-subtitle {
  font-family: Raleway;
  font-size: $fontSizeSM;
  font-style: italic;
}

.employee-form-title {
  font: $formLabelFont;
  margin-bottom: 10px;
  color: $darkgrey;
}

.row-question__dropdown {
  min-width: max-content;
  align-items: flex-end;
  height: 28px;
  margin-top: 0.2rem;

  .dropdown-toggle-value-default,
  .dropdown-toggle-value {
    height: 28px !important;
  }

  .components__form-dropdown-field,
  .dropdown-toggle {
    height: 28px !important;
  }

  .dropdown-toggle-icon-wrapper {
    height: 28px;
  }

  .dropdown-menu {
    margin-top: -20px !important;
  }

  p {
    height: 28px;
    margin: 0;
    padding-right: 10px;
    font-weight: $normal;
    line-height: 26px !important;
  }
}

.form-modal-question-container {
  display: flex;
  column-gap: 1rem;

  .components__label {
    margin-bottom: 3px !important;
    font-weight: $normal;
  }

  .form-modal-question-container-child .form-inner-wrapper input {
    height: 20px;
    line-height: 15px;
  }
}
