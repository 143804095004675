@import "../../index.scss";

.custom-toggle-header {
  background-color: aquamarine;
  display: flex;
  width: 100%;
  height: 79px;
}

.custom-toggle {
  color:blueviolet;
  background-color: white;
  display: flex;
  width: 100%;
  height: 79px;
}

.check-box-input {
  background-color: #F2F2F2;
  margin-right: $spacingLarge;
}


