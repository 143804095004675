@import "../../../index.scss";

.components__form-image-upload {
  .custom-file-upload {
    width: 160px;
    height: 160px;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .upload-button {
    width: 160px;
    cursor: pointer;
  }

  .upload-button-text {
    margin: 0px;
    color: $hyperLink;
    letter-spacing: -0.23px;
    font-size: $fontSizeSM;
    font-weight: $semiBold;
  }
  .recommend-text {
    margin-top: .5em;
  & p {
    margin-bottom: 0;
  }
}

  .img-wrap {
    position: relative;
    overflow: hidden;
  }

  .img-placeholder {
    position: absolute;
  }


  .img-upload:before{
    content: "";
    font-size: 90px;
    position: absolute;
    padding-top: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 160px;
    height: 160px;
    opacity: 0;
    transition: .5s ease;
    background-color: $darkgrey;
  }

  img {
    width: 100%;
    height: 100%;
    max-width:100%;
    max-height:100%;
    clip-path: circle(80px at center)
  }
}

// Styles for the image crop overlay

.crop-overlay {
  display: flex;
  justify-content: center;
  align-items: center;

  .crop-overlay-content {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: $spacingLarge;
    border-radius: 16px;
    position: relative;
    width: 608px;
    height: 413px;
    background-color: $white;

    @media (max-width: $breakpointMedium) {
      margin-left: 0px;
      max-width: 90%;
    }
  }

  .crop-overlay-header-title{
    margin: 0px;
    color: $black;
    letter-spacing: -0.34px;
    font-size: $fontSizeLG;
    font-weight: $semiBold;
    padding-bottom: $spacingStandard;
  }

  .controls-text {
    margin: 0px;
    color: #969896;
    letter-spacing: -0.34px;
    font-weight: lighter;
    font-size: 46px;
    padding-left: $spacingReallySmall;
    padding-right: $spacingReallySmall;
  }

  .crop-overlay-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .crop-overlay-header-close {
    cursor: pointer;
  }

  .crop-overlay--button {
    width: 200px;
    align-self: center;
    margin-top: $spacingLarge;
  }

  .cropContainer {
    position: relative;
    align-self: center;
    width: 270px;
    height: 270px;
    background-color: white;
  }

  .controls {
    padding: 16;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .sliderContainer {
    display: flex;
    flex: 1;
    width: 450px;
    align-items: center;
    align-self: center;
    justify-content: center;
    padding-top: $spacingStandard;

    @media (max-width: $breakpointMedium) {
      width: 100%;
    }
  }

  .crop-area {
    box-shadow:unset !important;
  }

  .slider {
    max-width: 300px !important;
    width: 300px !important;
    color: var(--primary) !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .rail {
  height: 4px !important;
  }

  .thumb {
    width: 16px !important;
    height: 16px !important;
  }
}

