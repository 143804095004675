@import '../../../index.scss';

.badge-container {
  margin: 24px 6px;
  background-color: #f7f7f7;

  .input-field-row {
    margin-top: 0;
    margin-bottom: 0;
  }
  form-control,
  input {
    background-color: #f7f7f7;

    &:focus {
      background-color: #f7f7f7;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

.badge-container-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  button {
    font-weight: 600;
    background: none;
    border: none;

    &#edit {
      color: $mutedBlue;
    }

    &#delete {
      color: $red;
    }
  }

  @media (max-width: $breakpointMedium) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.badge-buttons__separator {
  height: 23px;
  width: 1px;
  background-color: #969896;
  color: var(--primary);
  margin: 0px 5px 0px 5px;

  @media (max-width: $breakpointMedium) {
    height: unset;
    width: unset;
  }
}

.badge-error_message {
  padding-left: 0.75em;
  margin-top: -1em;
  padding-bottom: 20px;
}
