@import '../../../index.scss';

.components__tabswitch {
  width: 100%;
  display: flex;
  height: 57px;
  align-items: center;

  .tabswitch-item-wrapper {
    display: flex;
    align-items: center;
  }

  .tabswitch-item-text {
    margin: 0px;
    color: $darkgrey;
    font-size: $fontSizeLG;
    font-weight: $semiBold;
    cursor: pointer;

    @media (max-width: $breakpointMedium) {
      font-size: $fontSizeSM;
    }
  }

  .tabswitch-separator {
    height: 57px;
    width: 1px;
    background-color: $lightGrey;
    margin: 0px $spacingVeryLarge 0px $spacingVeryLarge;

    @media (max-width: $breakpointMedium) {
      margin: 0px $spacingReallySmall 0px $spacingReallySmall;
      width: 1px;
      height: 37px;
    }
  }

  .tabswitch-item-text-selected {
    padding-bottom: 4px;
    border-bottom: 4px var(--primary) solid;
    color: $black;
  }

  .--primary {
    border-bottom: 4px var(--primary) solid;
    color: $black;
  }

  .--secondary {
    border-bottom: 4px $resources solid;
    color: $black;
  }

  .--tertiary {
    border-bottom: 4px $community solid;
    color: $black;
  }
}
