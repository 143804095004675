@import '../../../index.scss';

.html-viewer-container {
  position: relative;
}

.more-less-btn {
  position: absolute;
  right: 0;
  top: 0;
  background-color: $white;
  padding: 0.2rem 0.2rem 0.2rem 1rem;
  font-weight: bold;
  text-decoration: underline;
  font-size: $fontSizeXS;
  cursor: pointer;
  user-select: none;

  &--less {
    top: auto;
    bottom: 0 !important;
    padding-bottom: 1.2rem;
  }
}

.more-not-selected {
  height: auto;
  overflow: auto;
}

.more-selected {
  height: 1.5rem;
  overflow: hidden;
}
