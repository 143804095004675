@import "../../index.scss";

.pages__helkpdesk-page {
  .helkpdesk-page-header {
    display: flex;
    justify-content: space-between;
  }

  .helkpdesk-page-title {
    font-size: 36px;
    line-height: 42px;
    text-align: left;
    color: $fontDark;
    font-weight: 800;
  }

  .helpdesk-page-card {
    display: flex;
    flex-direction: column;
    padding: $spacingLarge;
    background-color: $white;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top: $spacingSmall;
    margin-right: $spacingVeryLarge;
    margin-left: $spacingVeryLarge;

    @media (max-width: $breakpointMedium) {
      margin: $spacingStandard;
    }
  }

  .helkpdesk-page-card-title {
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    letter-spacing: -0.29px;
    color: $fontDark;
    font-weight: $semiBold;
  }

  .helkpdesk-page-card-description {
    font-size: $fontSizeMD;
    line-height: 21px;
    text-align: left;
    color: $fontDark;
  }

  .helkpdesk-page-card-email {
    font-size: $fontSizeMD;
    line-height: 21px;
    text-align: left;
    color: $hyperLink;

    a {
      color: $hyperLink;
    }
  }
}
