@import "../../../index.scss";

.partner-footer {
  background: $white;
  width: 100%;
  z-index: 1;
  position: relative;
  display: flex;
  min-height: 100px;
  padding-left: 24px;
  padding-right: 24px;
}

.partner-footer-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.partner-logos {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex: 3;
}

.partner-logo {
  max-height: 80px;
  max-width: 130px;
  width: auto;
  margin-right: 16px;
}

.download-partner-logo {
  max-height: 60px;
  max-width: 80px;
  width: auto;
  margin-right: 16px;
}

.partner-logo__sus-golf-brand {
  display: none;
}

@media (max-width: $breakpointMedium) {
  .partner-logo__sus-golf-brand {
    display: block;
    margin-left: -11px;
  }
}

.partner-logos-standard {
  display: flex;
  align-items: center;
  padding-left: $spacingStandard;
  margin-left: $spacingStandard;
}

.dynamic-partner-logos {
  padding-left: $spacingStandard;
  margin-left: $spacingStandard;
}

.section-border {
  border-left: 1px solid #cacbca;
}

.partner-footer-copyright {
  flex: 2;
  height: 19px;
  color: $darkgrey;
  font-family: Raleway;
  line-height: 19px;
}

.partner-footer-terms-link {
  text-decoration: none;
  color: $darkgrey;
  background-color: transparent;
  border: 0;
  &:hover {
    text-decoration: underline;
    color: $hyperLink;
}
}

@media (max-width: $breakpointMedium) {
  .partner-footer-inner-wrapper {
    padding: $spacingVeryLarge 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .partner-logo__sus-golf-brand {
    margin-bottom: $spacingVeryLarge;
  }

  .section-border {
    border: 0;
  }

  .partner-logos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .partner-logos-standard {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: $spacingLarge;
  }

  .dynamic-partner-logos {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: $spacingLarge;
  }

  .partner-footer-copyright {
    margin-bottom: $spacingVeryLarge;
  }
}

.tc-content {
  width: 100vw;
  margin: 0px;
  min-height: 100vh;
}

.tc-dialog  {
  width: 100vw;
  margin: 0px !important;
  min-height: 100vh;
  max-width: 100vw !important;
}

.tc-modal-close-button {
  background: transparent;
  border: 0;
  position: absolute;
  right: $spacingVeryLarge;
  top: $spacingVeryLarge;
  z-index: 99;
}