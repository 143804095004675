@import "../../../index.scss";

.pages__registration-complete-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacingLarge;
  min-width: 100%;
  min-height: 100vh;

  img {
    height: $logoHeight;
  }

  .registration-complete-page-card {
    width: 600px;
    min-height: 465px;
    background-color: $black;
  }

  .registration-complete-page-card-title {
    color: $white;
  }

  .registration-complete-page-title {
    position: absolute;
    top: $spacingLarge;
    left: $spacingLarge;
  }

  form-control,
  input {
    color: $white !important;
    background-color: $black;

    &:focus {
      background-color: $black
    }
  }

  .input-field-row {
    label {
      color: $white;
    }
  }

  .password-rules-description {
    P {
      color: $white;
    }

    li {
      color: $white;
    }
  }

  .registration-complete-description {
    padding-top: $spacingLarge;
    line-height: 19px;
    color: $white;
    font-size: $fontSizeSM;
    margin: 0px;
  }

  .registration-complete-button {
    width: 220px;
    margin-top: $spacingStandard * 4;
    background-color: primary;
  }

  .registration-complete-return {
    text-align: center;
    padding-top: $spacingStandard;
    padding-bottom: $spacingStandard * 2;
  }

  .registration-complete-return-text {
    color: var(--primary);
    font-size: $fontSizeSM;
    font-weight: 600;
  }
}
