@import "../../../index.scss";

.regstration-response-widget__page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacingLarge;
  min-width: 100%;
  min-height: 100vh;
  margin-top: $spacingVeryLarge * 2;
  margin-bottom: $spacingVeryLarge * 2;

  img {
    height: $logoHeight;
  }

  @media (min-width: $breakpointMedium) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .login-title {
    position: absolute;
    top: $spacingLarge;
    left: $spacingLarge;
  }

  .regstration-response-widget-card {
    width: 600px;
    min-height: 465px;
    background-color: $black;
  }

  .regstration-response-widget-card-title {
    color: $white;
  }

  .regstration-response-widget-body-text {
    color: $white;
    font-size: $fontSizeSM;
    line-height: 19px;;
    padding-top: $spacingLarge;
  }

  .regstration-response-widget-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $spacingVeryLarge * 2;
    margin-bottom: $spacingVeryLarge * 2;
    display: flex;
    flex-direction: column
  }

  .regstration-response-widget-return-to-home {
    margin-top: $spacingVeryLarge * 2;
    margin-bottom: $spacingVeryLarge;
    width: 200px;
  }
}

.regstration-response-widget__page-white {

  .regstration-response-widget-card {
    background-color: $white;
  }

  .regstration-response-widget-card-title {
    color: $darkgrey;
  }

  .regstration-response-widget-body-text {
    color: $darkgrey;
  }
}
