@import '../../../../index.scss';

.dashboard-facility-card {
  position: relative;
  width: 80%;
  display: flex;
  height: 200px;
  border-radius: 16px;
  box-shadow: $boxShadow;
  padding-right: 0px !important;
  padding-left: 0px !important;
  max-width: 100%;
  background-color: $white;

  @media (max-width: 1360px) {
    flex-direction: column;
    height: 600px;
    background-color: $taupe;
    margin-bottom: $spacingLarge;
  }

  @media (max-width: $breakpointExtraLarge) {
    width: 100%;
  }

  @media (max-width: $breakpointSmall) {
    height: 400px;
  }
}

.dashboard-facility-card-name {
  background-color: $white;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  @media (max-width: 1360px) {
    height: 200px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    border-top-right-radius: unset;
  }

  @media (max-width: $breakpointMedium) {
    height: 200px;
  }

  @media (max-width: $breakpointLarge) {
    height: 300px;
  }
}

.dashboard-facility-card-profile-image {
  width: 400px;
  height: 200px;
  background-color: $taupe;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: $taupe;
  background-position: center;
  background-size: 100% 200px;
  background-repeat: no-repeat;

  @media (max-width: 1360px) {
    align-self: center;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: unset;
    background-color: $taupe;
    width: 100%;
    height: 400px;
    background-size: 100% 400px;
  }

  @media (max-width: $breakpointLarge) {
    align-self: center;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: unset;
    background-color: $taupe;
    width: 100%;
    height: 300px;
    background-size: 100% 300px;
  }

  @media (max-width: $breakpointMedium) {
    align-self: center;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: unset;
    background-color: $taupe;
    width: 100%;
    height: 400px;
    background-size: 100% 400px;
  }

  @media (max-width: $breakpointSmall) {
    align-self: center;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: unset;
    background-color: $taupe;
    width: 100%;
    height: 200px;
    background-size: 100% 200px;
  }
}

.dashboard-facility--logo-image {
  height: 120px;
  width: 120px;
  border-radius: 60px;
  background-color: $taupe;
  position: absolute;
  left: 400px - 60px;
  margin-top: -60px;
  top: 50%;
  &.--shadow {
    box-shadow: $boxShadow;
  }

  @media (max-width: 1360px) {
    top: 400px;
    left: 50%;
    margin-left: -60px;
  }

  @media (max-width: $breakpointLarge) {
    margin-top: -60px;
    top: 50%;
    left: 50%;
    margin-left: -60px;
  }

  @media (max-width: $breakpointMedium) {
    top: 50%;
    margin-top: -20px;
    left: 50%;
    margin-left: -60px;
  }

  @media (max-width: $breakpointSmall) {
    top: 50%;
    margin-top: -100px;
    left: 50%;
    margin-left: -60px;
  }
}

.dashboard-facility-card-name-text {
  width: 100%;
  font-weight: $semiBold;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.34px;
  text-align: left;
  color: $fontDark;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  padding-right: $spacingStandard;
  margin: 0px;
}

.dashboard-facility-card-name-certified {
  font-weight: $semiBold;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.23px;
  text-align: left;
  color: var(--primary);
  margin: 0px;
}

.dashboard-facility-card-button-wrapper {
  position: absolute;
  bottom: $spacingStandard;
  right: $spacingStandard;
}

.dashboard-facility-card-name-button {
  background-color: $white;
  color: var(--primary);
  font-size: 13px;
  height: 39px;
  width: 190px;
  padding: 0px;

  &:hover {
    color: $white;
    background-color: var(--primary);
    transition: $transition;
  }
}

.dashboard-facility-card-name-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-left: 80px;
  justify-content: center;
}

.dashboard-facility-card-name-switch {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.23px;
  text-align: left;
  color: $lightGrey;
  margin: 0px;
  cursor: pointer;
}
