@import '../../../index.scss';

.row-question-field-row {
  margin: 40px 0px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  align-items: flex-end;

  .dropdown-toggle-value-default {
    padding-left: 14px;
    padding-right: 7px;
  }

  .components__label {
    margin-bottom: -0.2rem !important;
  }

  @media (max-width: $breakpointMedium) {
    width: 100%;
  }

  & .form-control {
    background-color: #f2f2f2;
    align-items: flex-start;
    &:focus {
      background-color: #f2f2f2;
    }
  }
  & .components__form-text-input-field .form-inner-wrapper .form-text-input-units {
    margin-bottom: 0px;
    color: $lightGrey;
    font-size: $fontSizeSM;
    letter-spacing: 0;
    line-height: 19px;
  }

  .row-question-unit-selector {
    margin-left: $spacingStandard;
  }

  .row-question__dropdown-container {
    .row-question__dropdown {
      min-width: max-content;
      align-items: center;
      p {
        margin: 0;
        padding-right: 10px;
        font-weight: $normal;
        line-height: 50px;
      }
    }
  }

  .components__form-text-input-field .form-inner-wrapper {
    input {
      height: 20px;
      line-height: 15px;
    }
}

  .input-field-row-text {
    padding-bottom: $spacingStandard * 2;
    width: 60%;
  }
}

.row-question-input-square .components__form-text-input-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  & label {
    margin-right: 8px;
  }
  & .form-inner-wrapper {
    border-bottom: none;
  }
  & input {
    border: 1px solid #969896;
  }
}

.redText input {
  color: $red;
}
