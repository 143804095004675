@import '../../../index.scss';

.components__accordion-card {
  width: 100%;
  display: flex;
  .accordion-card-container {
    width: 100%;
    margin: $spacingStandard 0px $spacingStandard 0px;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: $spacingStandard;
  }

  .--secondary {
    background-color: #f2f2f2;
    box-shadow: unset;
    padding: 0px;
    margin: $spacingStandard;
  }

  .--withoutBg {
    background-color: white;
    box-shadow: unset;
    padding: 0px;
    margin-left: 0px;
    color: $black;
  }

  .accordion-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    color: var(--primary);
    font-size: $fontSizeMD;
    letter-spacing: -0.26;
    line-height: 21px;
    text-align: left;
    font-weight: $semiBold;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    cursor: pointer;
  }

  .--black {
    color: $black;
  }

  .accordion-card-header-arrow {
    height: 24px;
    width: 24px;
    order: 2;
    transform: rotate(-90deg);
  }

  .--arrow-up {
    transform: rotate(0deg);
  }
  .accordion-card-header_statusIcon {
    margin-left: auto;
    margin-right: 24px;
    order: 2;
    // width: 24px;
    // height: 24px;
  }
  .--marginRight {
    margin-right: 56px;
  }

  .accordion-card-header-title {
    color: $hyperLink;
    font-weight: $semiBold;
    margin: 0px;
    order: 2;
  }

  .accordion-card-divider {
    height: 1px;
    width: 100%;
    background-color: $lightSilver;
    margin-top: $spacingSmall;
    margin-bottom: $spacingLarge;
  }

  .--small-divider {
    margin-bottom: 0px;
  }

  .--no-divider {
    background-color: transparent;
  }

  .accordion-card-body {
    padding-bottom: $spacingSmall;
  }

  .accordion-card-body-wrapper {
    background-color: #f2f2f2;
    padding: $spacingStandard;
  }
}

.accordion-card-header-primary {
  color: var(--primary) !important;
}
.accordion-card-header-secondary {
  color: $mutedBlue !important;
}
.accordion-card-header-tertiary {
  color: #d8740d !important;
}
.accordion-card-header-approach {
  color: #8a4cf6 !important;
}
.accordion-card-header-baseline {
  color: #007776 !important;
}
