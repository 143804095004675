@import "../../../../index.scss";

.dashboard-document-card {
    width: 17.77%;
    height: 200px;
    border-radius: 16px;
    box-shadow: $boxShadow;
    display: flex;
    flex-direction: column;
    min-width: 156px;
    align-items: center;
    background-color: $white;

    @media (max-width: $breakpointExtraLarge) {
      width: 100%;
      background-color:spacingStandard;
      margin-top: 34px;
    }
}
.dashboard-document-card-img {
    margin-top: 15px;
}
.dashboard-document-card-button {
    width: 150px;
    height: 39px;
    font-size: 13px;
    padding: 0px;
    margin-top: $spacingVeryLarge;
    padding-right: 2px;
    padding-left: 2px;
    bottom: 1em;
}

.dashboard-document-card-button {
  background-color: $white;
  color: var(--primary);

  &:hover {
    color: $white;
    background-color: var(--primary);
    transition: $transition;
  }
}
