@import "../../../index.scss";

.components__background-image {
  //background-image: url("../../../assets/background.png");
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
}
