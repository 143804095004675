:root {
  $primary: #4ca471;
  $washedOutPrimary: #e3eeea;
}

/**
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
 Colours
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
*/
$nature: #4ca471;
$resources: #2d78c8;
$community: #d8740d;
$approach: #8a4cf6;
$baseline: #007776;
$white: #fff;
$black: #1d1d1d;
$taupe: #f7f7f7;
$grey: #dce0e6;
$lightSilver: #d8d8d8;
$lightGrey: #969896;
$mutedGrey: #6e7682;
$darkgrey: #525352;
$mutedBlue: #2d78c8;
$hyperLink: #1d9ad7;
$red: #b00020;
$validationFailureTextColour: #b00020;
$validationFailureBorderColour: #b00020;
$yellow: #fbbc43;
/**
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
Shadows
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
*/
$boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
/**
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
 Size Variables
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
*/
$spacingReallySmall: 0.25em;
$spacingSmall: 0.5em;
$spacingStandard: 1em;
$spacingLarge: 1.5em;
$spacingVeryLarge: 3em;

$standardBorderRadius: 4px;
$panelBorderRadius: 8px;

$fontSizeXS: 12px;
$fontSizeSM: 16px;
$fontSizeMD: 18px;
$fontSizeLG: 24px;

// Small devices (landscape phones, 576px and up)
$breakpointSmall: 576px;
$breakpointSmallNumericalValue: 576;
// Medium devices (tablets, 768px and up)
$breakpointMedium: 768px;
$breakpointMediumlNumericalValue: 768;

// Large devices (desktops, 992px and up)
$breakpointLarge: 992px;
$breakpointLargeNumericalValue: 992;

// Extra large devices (large desktops, 1200px and up)
$breakpointExtraLarge: 1200px;
$breakpointExtraLargeNumericalValue: 1200;

$mediumButtonWidth: 118px;
/**
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
 Fonts Variables
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
*/

$fontDark: $darkgrey;

$bold: 700;
$semiBold: 600;
$normal: 400;
$main: 'Raleway', sans-serif;
$number: Ariel, sans-serif;

$h1: #{700 + ' 48px/72px ' + $main};
$h1LetterSpacing: -1.25px;
$h2: #{700 + ' 38px/58px ' + $main};
$h2LetterSpacing: -0.75px;
$h3: #{700 + ' 32px/48px ' + $main};
$h3LetterSpacing: -0.65px;
$h4: #{600 + ' 28px/44px ' + $main};
$h4LetterSpacing: -0.45px;
$h5: #{600 + ' 24px/29px ' + $main};
$h5LetterSpacing: -0.25px;
$h6: #{600 + ' 18px/32px ' + $main};
$h6LetterSpacing: -0.25px;

$body: #{400 + ' 15px/24px ' + $main};
$bodyLetterSpacing: -0.16px;

$caption: #{600 + ' 13px/12px ' + $main};
$captonLetterSpacing: -0.09px;

/**
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
 Animation Variables
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
*/
$transition: 0.3s ease-in;
/**
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
 Form Variables
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
*/
// Full Page Form Width
$fullPageFormWidth: 375px;
$fullPageFormHalfWidth: 65px;
$inputFieldHeight: 38px;

$formLabelFont: #{600 + ' 16px/24px ' + $main};
$formLabelSpacing: -0.23px;

/**
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
 Button Variables
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
*/

$largeBtn: 12px;
$mediumBtn: 10px;
$xtraSmallBorderRadius: 4px;
$smallBorderRadius: 10px;

$largeBorderRadius: 28px;

/**
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
 Logo Variable
= = = = = = = = = = = = = = = = = = = = = = = = = = = = =
*/

$logoHeight: 36px;
