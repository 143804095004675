@import '../../../index.scss';

.question-addnotes-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.question-addnotes__controls-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .question-addnotes-button-contianer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: $spacingStandard;
    button {
      margin-left: auto;
      transition: none !important;
    }

    @media (max-width: $breakpointSmall) {
      .question-addnotes-button-contianer-button-holder {
        margin: 0px;
        height: 8rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        > * {
          margin: 0px !important;
        }
      }
    }
  }
}
.question-addnotes-row {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  & .question-addnotes-row-title {
    font-family: Raleway;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 19px;
  }

  @media (max-width: $breakpointExtraLarge) {
    width: 70%;
  }

  @media (max-width: $breakpointMedium) {
    width: 100%;
  }
}

.question-addnotes_colapse {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  color: $resources;
  font-size: $fontSizeSM;
  letter-spacing: -0.26;
  line-height: 21px;
  text-align: left;
  font-weight: $semiBold;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  cursor: pointer;
}

.question-addnotes__content {
  flex: 1;
  word-break: break-word;
}

.question-addnotes__delete {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  button {
    font-weight: 600;
    background: none;
    border: none;

    &#edit {
      color: var(--primary);
    }

    &#delete {
      color: $red;
    }
  }
}

.question-addnotes__content-container {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0px 1rem;
}

.question-addnotes-button {
  min-width: 140px;
  border-radius: 4px;
  background-color: $white;
  border-style: unset;
  margin-top: 0;
  margin-bottom: 0;
  color: $black;
  font-weight: $bold;
}
.question-field-divider {
  height: 1px;
  width: 100%;
  background-color: #d8d8d8;
  // margin-top: 40px;
  // margin-bottom: 20px;
}

.question-addnotes-divider--margin {
  // margin-top: 16px;
  // margin-bottom: 16px;
}

.delete-notes-ctas {
  display: flex;
  justify-content: space-between;
  width: 100%;
  button {
    padding: 10px;
    font-size: 12px;
    width: 200px;
  }
}

.--hide {
  display: none;
}

.question-addnotes__separator {
  height: 23px;
  width: 1px;
  background-color: $lightSilver;
  color: var(--primary);
  margin: 0px 5px 0px 5px;

  @media (max-width: $breakpointMedium) {
    height: unset;
    width: unset;
  }
}

.answer-container {
  display: flex;
  @media (max-width: $breakpointMedium) {
    flex-direction: column;

    :nth-child(2) {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0.3rem;
    }
  }

  button {
    font-weight: 600;
    background: none;
    border: none;

    &#edit {
      color: var(--primary);
    }

    &#delete {
      color: $red;
    }
  }
}
