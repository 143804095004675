@import "../../../index.scss";

.card__wrapper {
  min-height: 450px;
  position: relative;
  background-color: $white;
  padding: $spacingLarge;
  border-radius: 16px;

  .card-title-wrapper {
    display: flex;
    align-items: center;
  }

  .card-title {
    display: flex;
    font-size: $fontSizeLG;
    color: $darkgrey;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
    padding-bottom: 4px;
    border-bottom: 4px var(--primary) solid;
  }

  .card-title-underline {
    height: 4px;
    width: 50px;
    background-color: var(--primary);
    margin-top: 10px;
    margin-bottom: $spacingSmall;
  }

  .card-contact-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: $spacingVeryLarge;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 2;

    p {
      color: $lightGrey;
      font-size: $fontSizeSM;
      margin: 0px;
    }

    a {
      color: $hyperLink;
    }
  }

  .card-content-wrapper {
    z-index: 2;
    position: relative;
  }

  .overlay {
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 1;
  }
}
