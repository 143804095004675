@import "../../../index.scss";

.components__form-date-picker-field {
  display: flex;
  flex-direction: column;

  .date-picker-custom-input {
    display: flex;
    flex-direction: row;
    width: 200px;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    background-color: $white;
    border: 1px solid $lightGrey;
    padding: $spacingSmall;
    border-radius: 6px;
  }

  .custom-input-text {
    margin: 0px;
    color: $darkgrey;
    font-size: $fontSizeSM;
    background-image: url("../../../assets/date-picker.svg");
    background-repeat: no-repeat;
    background-position: 153px;
    padding: $spacingSmall;
    outline-color: var(--primary);
    border-radius: 4px;
    border: 1px solid $lightGrey;
  }
}
