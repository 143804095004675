@import "../../../index.scss";

.pages__login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacingLarge;
  min-width: 100%;
  min-height: 100vh;

  img {
    height: $logoHeight;
  }

  .login-title {
    position: absolute;
    top: $spacingLarge;
    left: $spacingLarge;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 440px;
    width: 100%;
    margin-top: $spacingVeryLarge * 2;
    margin-bottom: $spacingVeryLarge * 2;

    @media (min-width: $breakpointMedium) {
      flex-direction: row;
      width: 900px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .login-form-card {
    flex-grow: 1;
    width: 100%;
    min-height: 440px;
    background-color: white;
    border-radius: 0px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;

    @media (min-width: $breakpointMedium) {
      border-radius: 0px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }
  }

  .login-form-card-borders {
    border-radius: 25px;
  }

  .forgotten-password-wrapper {
    text-align: center;
    margin-top: $spacingStandard;
  }

  .forgotten-password-text {
    color: var(--primary);
    font-size: $fontSizeSM;
    font-weight: 600;
  }

  .register-card {
    flex-grow: 1;
    width: 100%;
    min-height: 440px;
    position: relative;
    background-color: $black;
    padding: $spacingLarge;
    border-radius: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    @media (min-width: $breakpointMedium) {
      border-bottom-left-radius: 0px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  .register-description {
    font-size: $fontSizeSM;
    color: $white;
    margin-top: $spacingLarge;
  }

  .register-button {
    margin-top: $spacingStandard * 3;
    width: 220px;
    background-color: transparent;
    color: var(--primary);
    border-width: 2px;
    z-index: 2;
    &:hover {
      color: $black;
      background-color: var(--primary);
      transition: $transition;
    }
  }

  .register-title {
    color: $white;
  }
}
