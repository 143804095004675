@import '../../../index.scss';

.components__data-grid {
  display: flex;
  flex-direction: column;
  padding-bottom: $spacingStandard;
  row-gap: 1.5rem;

  .dataGrid__mass_table {
    border: 1px solid $lightSilver;
    &.table {
      margin-bottom: 0.5rem;
    }
  }
  .collapsing {
    transition: none !important;
  }
  .bold_text {
    font-weight: $bold;
  }

  .data-grid-delete-button {
    outline: none;
    background-color: transparent;
    border-color: transparent;
    padding-inline: 0.2rem;

    @media (max-width: $breakpointSmall) {
      padding: 0px;
    }
  }

  .btn-click-disabled {
    cursor: not-allowed !important;
  }

  .data-grid-edit-button {
    outline: none;
    background-color: transparent;
    border-color: transparent;
    color: var(--primary);
    font-size: fontSizeSM;
    font-weight: $semiBold;

    @media (max-width: $breakpointSmall) {
      padding: 0px;
    }
  }
  .data-grid-button_colapse {
    font-size: $fontSizeSM;
    letter-spacing: -0.26;
    text-align: left;
    font-weight: $semiBold;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-right: 1rem;
    cursor: pointer;

    @media (max-width: $breakpointSmall) {
      padding: 0px;
    }
  }
  .right_float {
    float: right;
  }

  .data-grid-add-button {
    min-width: 95px;
    border-radius: 4px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    margin-top: $spacingReallySmall;
    align-self: flex-start;
    border: none;
    padding: 0.75rem 1rem;
  }

  .data-grid-add-button-text {
    color: #000000;
    font-size: $fontSizeMD;
    font-weight: $semiBold;
    text-align: left;
    padding: 0px;
    margin: 0px;
    margin-left: $spacingSmall;
  }
  & table:last-child {
    margin-bottom: 0;
  }

  table {
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    margin-top: $spacingLarge;
    border: 1px solid $red;
  }

  table tr {
    background-color: white;
    border: 1px solid $lightSilver;
    border-radius: 8px;
    height: 56px;
  }

  table th,
  table td {
    border: 1px solid $lightSilver;
    padding: 0.4em;
    text-align: left;
    font-size: $fontSizeXS;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word;
    word-break: break-word;

    p,
    li {
      font-size: 13px;
    }
  }

  table th {
    font-size: $fontSizeXS;
    color: #000000;
    font-weight: $semiBold;
  }

  @media (max-width: $breakpointSmall) {
    table {
      border: 0;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      display: block;
      margin-bottom: 0.625em;
      height: unset;
      background-color: white;
    }

    table td {
      border-bottom: 1px solid $lightSilver;
      border-top: unset;
      display: block;
      font-size: $fontSizeSM;
      color: $black;
      line-height: 19px;
      letter-spacing: -0.23px;
      margin: 0px;
      text-align: right;
    }

    table td::before {
      content: attr(data-label);
      font-size: $fontSizeSM;
      color: #000000;
      font-weight: $semiBold;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    table td:last-child {
      border-bottom: 0;
    }

    .dataGrid__mass_table table tr {
      border-radius: 1px;
      border: 1px solid $lightSilver;
      margin-bottom: 0px;

      td {
        border: unset;

        & span {
          margin-left: 6px;
        }
      }
    }
  }
}

.table-row-answer-input-field {
  > div {
    border-bottom: 0px solid #969896 !important;
  }
}

.veritcal-table-td {
  padding-left: 1.5rem !important;
  @media (max-width: $breakpointSmall) {
    padding-left: 1rem !important;
    overflow: hidden;
  }
}

.data-grid-modal {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  .data-grid__dropdown-container {
    margin-left: $spacingStandard;
  }
}

.myClassname,
.react-datepicker {
  border: 1px solid$lightGrey;
  background-color: white;
  outline: none;
}

.myClassname,
.react-datepicker__day--selected {
  background-color: var(--primary) !important;
  border-radius: 23px;
}

/* Styles for On Hover of selected */
.myClassname,
.react-datepicker__day--selected:hover {
  background-color: var(--primary) !important;
  border-radius: 23px;
}

.myClassname,
.react-datepicker__header {
  background-color: white;
  border-bottom: white;
}

.myClassname,
.react-datepicker__day--today {
  color: $black;
  border-radius: 23px;
}

.myClassname,
.react-datepicker__day {
  &:hover {
    border-radius: 23px;
    background-color: var(--primary);
    color: $white;
  }
}

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-color: var(--primary);
  top: 12px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--primary);
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  border-style: hidden;
}

table tr {
  background-color: $white;
  border: 1px solid #e6e6e5;
  border-radius: 8px;
  height: 56px;
}

table th,
table td {
  padding: 0.625em;
  text-align: left !important;
  vertical-align: middle !important;
}

table th {
  font-size: $fontSizeSM;
  color: #100f26;
  font-weight: $semiBold;
  letter-spacing: 0px;
}

table thead {
  tr th {
    border-bottom: 1px solid $mutedGrey;
  }
}

.last-table-tr-th {
  padding: 1rem;
  width: 45px;
}

.temp-example-td {
  color: $mutedGrey;
  font-style: italic;
  font-size: 14px;
}

.dataGrid__mass_table table {
  @media (max-width: 768px) {
    & thead tr :first-child {
      width: 0px;
      background-color: aqua;
      & h6 {
        position: absolute;
        left: 50%;
        transform: translate(-66%, -100%);
        width: 60%;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.3em;
      }
    }
    & thead th:nth-child(2) {
      width: 70%;
    }
    & thead th:nth-child(3) {
      width: 30%;
    }
  }
  @media (max-width: 375px) {
    & thead tr :first-child {
      background-color: aqua;
      width: 0px;
      & h6 {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -100%);
        width: 60%;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.3em;
        color: $red;
      }
    }
    & thead th:nth-child(2) {
      width: 70%;
      background-color: aqua;
    }
    & thead th:nth-child(3) {
      width: 30%;
    }
  }
}

.--no-border {
  border: unset !important;
}

.dataGrid_table__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $lightSilver;
  background-color: white;
  button {
    font-weight: 600;
    background: none;
    border: none;

    &#edit {
      color: var(--primary);
    }

    &#delete {
      color: $red;
    }
  }
}

.input-data-grid-field-row {
  padding-bottom: $spacingStandard * 2;
  margin-top: 10px;
  display: flex;
  width: 100%;
  column-gap: 1rem;
  align-items: center;
  label {
    color: #100f26;
    font-weight: $normal;
  }
}

.input-data-grid-field-row-unit {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  column-gap: 1rem;
}

.input-field-row-text {
  padding-bottom: $spacingStandard * 2;
  display: flex;
  width: 100%;
  column-gap: 1rem;
  align-items: center;
}

.input-data-grid-dropdown-row {
  margin-top: $spacingLarge;
  margin-bottom: $spacingLarge;
}

.form-title {
  color: $darkgrey;
  font-size: $fontSizeSM;
  font-weight: $semiBold;
  margin: 0px;
}

.data-grid-unit-selector {
  margin-bottom: $spacingStandard * 2;
  margin-left: $spacingVeryLarge;
}
.margin-left {
  margin-left: $spacingLarge;
  padding-top: 0.5rem;
  min-height: 3rem;
  @media (max-width: $breakpointSmall) {
    margin-left: 1rem !important;
  }
}

.hidden-on-smaller-Screens {
  @media (max-width: $breakpointMedium) {
    display: none;
  }
}

.shown-on-smaller-screen {
  display: none;

  @media (max-width: $breakpointMedium) {
    display: table-row;
    td {
      border: none !important;
    }

    td:nth-child(2) {
      display: flex;
      justify-content: flex-end;
      border: none !important;
      margin-top: 0.5rem;
    }
  }
}

.placeholder-answer-text {
  color: $mutedGrey;
  font-style: italic;
  font-size: 14px;
}

.right-align-table-tr {
  @media (max-width: $breakpointMedium) {
    :nth-child(1) {
      border: 0px;
    }
    :nth-child(2) {
      text-align: right !important;
      border: 0px;
    }
  }
}

.delete-table-row-ctas {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 1rem;
  button {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    border-radius: 0.25rem;
  }
}
