@import "../../index.scss";

.document-library-page-header {
  width: 100%;
  height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.document-library-tabswitch {
  margin-bottom: $spacingVeryLarge;
}
