@import "../../../../index.scss";

.pages__page {
  width: 100%;
  height: 100vh;
  padding: $spacingVeryLarge;

  @media (max-width: $breakpointMedium) {
    padding: $spacingStandard;
  }

  &.annual-data {
    .accordion-card-body-wrapper {
      background-color: transparent;
      border-radius: 4px;
    }
  }
}

.header-title {
  font-size: 36px;
  font-weight: $bold;
  color: $darkgrey;
}

.separator {
  height: 22px;
  background-color: white;
  width: 100%;
}

.questions-wrapper {
  width: 100%;
}
