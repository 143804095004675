@import '../../../index.scss';

.btn__basic {
  border-radius: $xtraSmallBorderRadius;
  transition: $transition;
  color: $white;
  font-size: $fontSizeSM;
  letter-spacing: -0.23;
  display: inline;
  background: var(--primary);

  &:hover {
    background: var(--primary);
    transition: $transition;
  }

  &.--loading {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .button__loading-wrapper {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  .button__loading-spinner {
    animation-name: spinner;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    .opacity-25 {
      opacity: 0.25;
    }
    .opacity-75 {
      opacity: 0.75;
    }
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.large {
  padding-top: $largeBtn;
  padding-bottom: $largeBtn;
  padding-left: $spacingStandard;
  padding-right: $spacingStandard;
  font-weight: bold;
}

.medium {
  padding-top: $mediumBtn;
  padding-bottom: $mediumBtn;
  font-weight: bold;
}
