@import "../../../index.scss";

.components_facility-profile-details-form {
  .facility-profile-details-form-title {
    color: $darkgrey;
    font-size: 20px;
    letter-spacing: -0.29px;
    text-align: left;
    font-weight: $semiBold;
    padding-bottom: $spacingSmall;
  }

  .facility-profile-details-form-wrapper {
    display: flex;
    flex-direction: row;
    max-width: 1440px;

    @media (max-width: $breakpointMedium) {
      width: 100%;
    }
  }

  .facility-profile-details-form-details-wrapper-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: $spacingVeryLarge;
    flex-wrap: wrap;

    @media (max-width: $breakpointMedium) {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .facility-profile-details-form-details-edit {
    display: flex;
    flex-direction: column;
    max-width: 40%;
    flex: 40%;
    min-width: 338px;
    min-height: 772px;
    padding: $spacingLarge;
    background-color: $white;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    flex-wrap: wrap;

    @media (max-width: 1222px) {
      flex-direction: column;
      flex: 70%;
      max-width: 70%;
      min-width: 526px;
    }
    @media (min-width: 1349px) {
      flex: 45%;
      max-width: 45%;
    }
  }

  @media (max-width: $breakpointLarge) {
    .facility-profile-details-form-details-edit {
      max-width: 100%;
      width: 100%;
      min-width: unset;
    }

    .facility-profile-form-image-upload-edit {
      width: 100%;
      min-width: 100% !important;
      max-width: 100% !important;
    }
    .facility-profile-form-image-wrapper {
      .photo-upload-wrapper {
        width: 270px;
        text-align: center;
      }

      .upload-button {
        text-align: left;
      }
      .recommend-text {
        text-align: left;
      }
    }
  }

  .facility-profile-form-image-container {
    height: 375px;
    padding: 1.5em;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (max-width: 1222px) {
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      @media (max-width: $breakpointLarge) {
        height: 705px;
      }
  }

  .facility-profile-form-image-unit {
    height: 375px;
    padding: 1.5em;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  @media (max-width: 1222px) {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .facility-profile-form-image-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: $spacingStandard;

    @media (max-width: $breakpointLarge) {
      flex-direction: column;
      align-items: center;
    }
  }

  .facility-profile-form-image-upload-edit {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    flex: 50%;
    min-width: 526px;
    row-gap: 20px;
    @media (max-width: 1222px) {
      flex-direction: column;
      flex: 70%;
      max-width: 70%;
      height: unset;
      min-height: 300px;
      justify-content: center;
      align-items: center;
      margin-top: $spacingVeryLarge;
      margin-left: 0px;
    }
  }

  .facility-profile-form-title {
    font-size: 20px;
    font-weight: $semiBold;
    letter-spacing: -0.29;
    line-height: 24px;
  }

  .input-field-row-column {
    width: 48%;
    margin-top: $spacingLarge;
    margin-bottom: $spacingStandard;
  }

  .input-field-row {
    width: 100%;
    margin-top: $spacingLarge;
    margin-bottom: $spacingStandard;

    @media (max-width: $breakpointMedium) {
      width: 100%;
      padding-top: $spacingVeryLarge;
    }
  }

  .facility-profile-details-form-details-wrapper-create {
    background-color: $taupe;
    width: 100%;
    flex-direction: column;
    padding: $spacingLarge;
  }

  .facility-profile-details-form-details-create {
    padding: 0px;
  }
}

.components_facility-profile-form-create {
  width: 100%;

  form-control,
  input {
    background-color: $taupe;

    &:focus {
      background-color: $taupe;
    }
  }

  .img-placeholder {
    background-color: white;
  }
}

.facility-profile-details-preview-title {
  font-size: $fontSizeSM;
  color: $black;
  font-weight: $semiBold;
  line-height: 19px;
  letter-spacing: -0.23px;
  text-align: left;
  margin: 0px;
  padding-right: $spacingSmall;
  padding-top: $spacingSmall;
  padding-bottom: $spacingSmall;
}

.facility-profile-details-preview-value {
  font-size: $fontSizeSM;
  color: $black;
  line-height: 19px;
  letter-spacing: -0.23px;
  text-align: left;
  margin: 0px;
  padding-top: $spacingSmall;
  padding-bottom: $spacingSmall;

  @media (max-width: $breakpointMedium) {
    padding: 0px;
  }
}

.profile-facility-team-modal-button {
  align-self: flex-end;
}

.facility-profile-details-preview-logo {
  width: 160px;
  height: 160px;
  margin-right: $spacingVeryLarge;
}

.facility-profile-details-preview-image {
  width: 270px;
  height: 160px;
}

.facility-profile-details-preview-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;

  @media (max-width: $breakpointMedium) {
    flex-direction: column;
  }
}

.facility-profile-details-preview-modal-image-wrapper {
  display: flex;
  flex-direction: row;

  @media (max-width: $breakpointMedium) {
    flex-direction: column;
  }
}

.facility-profile-details-preview-modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

@media (max-width: $breakpointSmall) {
  .facility-profile-tabswitch {
    flex-direction: column;
    align-items: flex-start;
    height: unset;
    .tabswitch-item-text {
      margin-bottom: $spacingSmall;
    }
    .tabswitch-separator {
      display: none;
    }
  }
}

.facility-profile-form-image {
  & img {
    clip-path: unset;
  }
}
