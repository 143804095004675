@import '../../../../index.scss';

.question-tile {
  &.--primary {
    border: 2px solid var(--primary);
    color: var(--primary);
  }

  &.--secondary {
    border: 2px solid $resources;
    color: $resources;
    circle {
      fill: $resources;
    }
  }

  &.--tertiary {
    border: 2px solid $community;
    color: $community;
    circle {
      fill: $community;
    }
  }

  &.--yellow {
    border: 2px solid $yellow;
    color: $yellow;
    circle {
      fill: $yellow;
    }
  }

  border-radius: $panelBorderRadius;
  padding: $spacingSmall;
  display: flex;
  font-weight: $semiBold;
  margin-bottom: $spacingStandard;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  a,
  &:hover {
    text-decoration: none;
  }
}

.question-guidance {
  p {
    font-style: italic;
  }
}
