@import "../../../index.scss";

.pages__forgotten-password-link-sent-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  max-width: 500px;
  padding: $spacingStandard;
  margin-bottom: 150px;

  @media (min-width: $breakpointSmall) {
    margin-bottom: 0px;
  }

  img {
    height: $logoHeight;
  }

  .forgotten-password-title {
    position: absolute;
    top: $spacingLarge;
    left: $spacingLarge;
  }

  .forgotten-password-link-sent-card {
    width: 100%;
    min-height: 465px;
    top: $spacingVeryLarge;

    @media (min-width: $breakpointSmall) {
      width: 600px;
      top: 0px;
    }
  }

  .forgotten-password-description {
    padding-top: $spacingLarge;
    line-height: 19px;
    color: $darkgrey;
    font-size: $fontSizeSM;
    margin: 0px;
  }

  .forgotten-password-link-sent-button {
    width: 220px;
    margin-top: $spacingVeryLarge;
    margin-bottom: $spacingVeryLarge;
    position: relative;
    z-index: 2;
  }
}
