@import "../../../../index.scss";


.best-practices-section-separator {
  width: 100%;
  height: 0.5px;
  background-color: $darkgrey;
  margin-top: 2em;
  margin-bottom: $spacingLarge;
}

.best-practices-summary-columns {
  min-width: 275px;
  margin-bottom: $spacingStandard;
}