@import '../../../index.scss';

.notification-bar {
  width: 100%;
  padding: 10px 3%;
  color: #31708f;
  background: #d9edf7;
  position: absolute;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
  display: flex;
  z-index: 9999;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  
  .close{
    position: relative;
    cursor: pointer;
    padding-left: 20px;
  }
}