@import "../../../index.scss";

.pages__registration-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacingLarge;
  min-width: 100%;
  min-height: 100vh;
  margin-top: $spacingVeryLarge * 2;

  img {
    height: $logoHeight;
  }

  @media (min-width: $breakpointMedium) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .login-title {
    position: absolute;
    top: $spacingLarge;
    left: $spacingLarge;
  }

  .registration-form-card {
    width: 600px;
    min-height: 858px;
    background-color: $black;
    margin-bottom: 150px;
  }

  .forgotten-password-wrapper {
    text-align: center;
    margin-top: $spacingStandard;
  }

  .forgotten-password-text {
    color: var(--primary);
    font-size: $fontSizeSM;
    font-weight: 600;
  }

  .register-card {
    flex-grow: 1;
    width: 100%;
    min-height: 440px;
    position: relative;
    background-color: $black;
    padding: $spacingLarge;
    border-radius: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    @media (min-width: $breakpointMedium) {
      border-bottom-left-radius: 0px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  .register-description {
    font-size: $fontSizeSM;
    color: $white;
    margin-top: $spacingLarge;
  }

  .register-button {
    margin-top: $spacingStandard * 3;
    width: 220px;
    background-color: transparent;
    color: var(--primary);
    border-width: 2px;
    z-index: 2;
    &:hover {
      color: $black;
      background-color: var(--primary);
      transition: $transition;
    }
  }

  .register-title {
    color: $white;
  }

  .registration-return {
    text-align: center;
    margin-top: $spacingStandard;
  }

  .registration-return-text {
    color: var(--primary);
    font-size: $fontSizeSM;
    font-weight: 600;
    position: relative;
    z-index: 2;
  }
}
