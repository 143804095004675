@import '../../../index.scss';

.components__multiple-choice-question {
  .dropdown-menu {
    min-width: 340px;
  }

  @media (max-width: $breakpointSmall) {
    .dropdown-menu {
      max-width: 50% !important;
    }
  }

  .question-checkbox-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .question-checkbox-wrapper > div {
    margin-top: $spacingSmall;
    margin-bottom: $spacingSmall;
  }
}

.toggle-remove-button {
  background: none;
  border: none;
  margin-left: $spacingSmall;
  padding: 1px 0;
}
.answer-modal-footer {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  button {
    padding: 10px;
    font-size: 14px;
    width: 250px;
  }
}

.answer-modal-btn__left {
  background-color: $white;
  color: var(--primary);
  &:hover {
    background-color: var(--primary);
    color: $white;
    transition: $transition;
  }
}

.answer-modal-btn__right {
  background-color: $validationFailureTextColour;
  border-color: $validationFailureTextColour;
  &:hover {
    background-color: $white;
    color: $validationFailureTextColour;
    transition: $transition;
    border-color: $validationFailureTextColour;
  }
}
