@import '../../../index.scss';

.components__form-text-input-field {
  label {
    font: $formLabelFont;
    letter-spacing: $formLabelSpacing;
    margin: 0px;
    color: $darkgrey;
  }

  input {
    height: $inputFieldHeight;
    border-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    outline: none;
    padding: 0px;
    color: $darkgrey;
    font-size: $fontSizeSM;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .--number {
    font-family: $number;
    width: 100px;
    border-bottom: unset;
  }

  .form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .form-inner-wrapper {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $lightGrey;
    & input {
      min-width: 40px;
    }
  }

  .form-control-container {
    width: 100%;
  }

  .form-text-input-units {
    color: $lightGrey;
    font-size: fontSizeSM;
    line-height: 19px;
    letter-spacing: -0.23px;
    margin-left: 16px;
    margin-bottom: 8px;
    align-self: flex-end;
  }

  .error {
    border-bottom: 1px solid $validationFailureTextColour;
  }

  .show-password {
    display: flex;
    align-items: flex-end;
    color: var(--primary);
    margin-left: $spacingStandard;
    font-weight: 600;
    letter-spacing: -0.23;
    z-index: 99;
  }

  .--no-border {
    border-bottom: unset;
  }

  .form-text-input-field-number {
    width: 100px;
    height: 1px;
    background-color: $lightGrey;
  }
}
