@import "../../../index.scss";

.section-renderer__heading {
  margin-left: $spacingReallySmall;
  margin-top: $spacingSmall;
}

.questions-wrapper-subsection-title {
  display: flex;
  background-color: $white;
  color: $darkgrey;
  font-weight: $bold;
  font-size: $fontSizeMD;
}

.section-renderer__annual-data-accordion {
  position: relative;
}

.section-renderer__annual-data-hidden-field {
  position: absolute;
  top: -60px;
}
