@import "../../index.scss";

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $spacingLarge;
  min-width: 100%;
  min-height: 100vh;
}


