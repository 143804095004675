@import "../../../index.scss";

$sidebar-bg-color: $white;
$sidebar-color: $black !default;
$sidebar-width: 256px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: var(--primary) !default;
$submenu-bg-color: $white !default;
$submenu-bg-color-collapsed: $white !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.sidebar-parent {
  position: fixed;
  left: 0;
  z-index: 2;
}

.behind-the-scenes {
  width: 250px;

  @media (max-width: $breakpointMedium) {
    display: none;
  }
}

.pro-sidebar {
  height: 100vh;
  z-index: 9;

  .overlay {
    top: 72px;
  }
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 0;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  content: none !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item
  .pro-arrow-wrapper {
  display: block;
}

.pro-sidebar .pro-menu {
  padding-top: 0;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 12px 35px 12px 20px;
}

.pro-sidebar .pro-menu {
  & .sidebar-nav--item:after {
    content: "";
    position: absolute;
    left: 5%;
    height: 3px;
    width: 90%;
    border-bottom: 1px solid var(--primary);
  }
  & .sidebar-nav--lastitem:after {
    content: none;
  }
  & .sidebar-nav--selectedItem {
    background-color: $taupe;
    &:after {
      content: "";
      position: absolute;
      left: 5%;
      height: 3px;
      width: 90%;
      border-bottom: 1px solid var(--primary);
    }
    & > div > span > a {
      color: var(--primary) !important;
    }
  }
  & .pro-menu-item .sidebar-submenu--item {
    border-bottom: 1px solid var(--primary);
  }
}

.react-slidedown .pro-inner-list-item .pro-inner-item {
  font-weight: 400 !important;
}

.pro-item-content {
  font-size: $fontSizeSM;
}

.pro-sidebar-content {
  height: 100%;
}

.pro-menu {
  height: 100%;
  > ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 20px;
  }
}

.sidebar-logout {
  margin-bottom: 31px;

  &:before {
    content: "";
    position: absolute;
    left: 5%;
    height: 3px;
    width: 90%;
    border-top: 2px solid $lightSilver;
  }
  & a {
    color: $black !important;
  }
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  font-weight: $semiBold;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow {
  display: inline-block;
  border-style: solid;
  border-color: var(--primary);
  border-width: 0 3px 3px 0;
  padding: 5px;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu.open
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow {
  border-color: var(--primary);
}

.sidebar-footer-container {
  margin-top: auto;
  margin-bottom: 90px;
}

.sidebar-footer-logo {
  padding-right: 24px;
  max-height: 80px;
  max-width: 274px;
  display: flex;
  margin-left: 10%;
  .partner-logo {
    width: 100%;
    height: 46px;
  }
}

@media (max-width: $breakpointMedium) {
  .sidebar-footer-logo {
    display: none;
  }

  .sidebar-logout {
    margin-bottom: 0;
    padding-bottom: 90px;
  }
}

.sidebar-image {
  position: absolute;
  bottom: 28px;
  left: 0;
  margin-left: -50px;
  z-index: -1;
}

.pro-sidebar .pro-menu .sidebar-nav--item:after {
  content: "";
  position: absolute;
  left: 5%;
  height: 1px;
  width: 90%;
  padding-bottom: 0px;
  border-bottom: 1px solid var(--primary);
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background-color: transparent;
}
