@import "../../../index.scss";

.components_profile-details-form {
  width: 1104px;
  min-height: 295px;
  padding: $spacingLarge;
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .profile-details-form-title {
    color: $darkgrey;
    font-size: 20px;
    letter-spacing: -0.29px;
    text-align: left;
    font-weight: $semiBold;
    padding-bottom: $spacingSmall;
  }

  .profile-details-form-wrapper {
    display: flex;

    @media (max-width: $breakpointLarge) {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .profile-details-form-details-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: $spacingVeryLarge;

    @media (max-width: $breakpointLarge) {
      padding-left: 0px;
    }
  }

  .profile-details-form-details {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: $breakpointLarge) {
      flex-direction: column;
      width: 100%;
    }

    @media (max-width: $breakpointLarge) {
      flex-direction: column;
      width: 100%;
    }
  }

  .input-field-row {
    width: 48%;

    @media (max-width: $breakpointLarge) {
      width: 100%;
      padding-top: $spacingStandard;
    }
  }

  .profile-details-form-password-button {
    background-color: $white;
    color: var(--primary);
    transition: $transition;

    &:hover {
      background-color: var(--primary);
    color: $white;
    }
  }
}
