@import "../../../../index.scss";

.oncourse-reports__panel-title {
  margin-bottom: $spacingStandard;
}

.oncourse-reports__panel--content {
  overflow: hidden;
  & img {
    max-width: 200px;
    max-height: 190px;
    margin-right: $spacingLarge;
    padding: 0 0 12px 0;
    object-fit: contain;
    object-position: top;
    float: left;
  }

  ul,
  ol {
    padding: 0;
  }

  li {
    list-style-position: inside;
    color: $black;
  }
}
.oncourse-reports__panel-title {
  color: $black;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
}

.oncourse-reports__panel--text p {
  font-size: $fontSizeSM;
  letter-spacing: 0;
  line-height: 21px;
  color: $black;
  word-wrap: break-word;
}
.oncourse-reports__panel--list {
  margin-top: 16px;
  color: $black;
  font-size: $fontSizeSM;
  letter-spacing: 0;
  line-height: 19px;
}
.oncourse-reports__links-container {
  background-color: $taupe;
  padding-top: $spacingStandard;
  padding: $spacingStandard $spacingStandard $spacingSmall;
  & .oncourse-reports__links-title {
    color: $black;
    font-size: $fontSizeMD;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 8px;
  }
  & .oncourse-reports__panel--downloads {
    display: flex;
    flex-direction: column;
    p {
      word-wrap: break-word;
      margin-bottom: $spacingSmall;
    }
    .download-resources-anchor {
      color: $hyperLink;
      font-size: $fontSizeMD;
      font-weight: $semiBold;
      letter-spacing: 0;
      line-height: 21px;
      align-items: normal;
      background-color: rgba(0, 0, 0, 0);
      border-style: none;
      box-sizing: content-box;
      cursor: pointer;
      text-align: start;
      &:hover {
        text-decoration: none;
      }
    }
    .padding_left {
      padding-left: 6px;
    }
  }
}

@media (max-width: $breakpointMedium) {
  .oncourse-reports__pannel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & .oncourse-reports__panel--content img {
      width: 200px;
      height: 190px;
      float: none;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
