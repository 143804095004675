
@import "../../../index.scss";

.components__modal-sidebar {  
  .overlay {
    background-color: rgba(0, 0, 0, 0.55);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
    z-index: 5;
  }
  
  .overlay.Show {
    display: block;
  }
  
  .Modal {
    position: fixed;
    right: -30%;
    top: 70px;
    bottom: 0px;
    background-color:white;
    width: 30%;
    height: 100vh;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
    padding: 0 12px 12px;
    transition: all 0.3s ease-out;
    z-index: 10;

    @media (max-width: $breakpointMedium) {
      width: 90%;
      right: -90%;
    }
  }
  
  .Modal.Show {
    right: 0;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    border: 0;
    padding: 0;
    padding-top: $spacingStandard;
    margin-bottom: $spacingStandard;
  }

  .modal-title {
    text-align: center;
  }
  
  .modal-close-button {
    background: transparent;
    border: 0;
  }

  .modal-body-area {
    margin-bottom: $spacingStandard;
  }

  .modal-footer-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .modal-buttons {
    justify-content: center;
    border-top: none;
  }

}