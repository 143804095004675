@import "../../../index.scss";

.panel-container {
  border-radius: $panelBorderRadius;
  box-shadow: $boxShadow;
  padding: $spacingLarge;
  background: $white;
  background-position: right bottom;


  &.--border {
    border: 2px solid var(--primary);
  }
   &.--noshadow {
    box-shadow: none!important;
  }
}

.panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacingStandard;
}
