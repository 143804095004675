@import "../../../index.scss";

.pages__password-reset-success-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  max-width: 500px;
  padding: $spacingStandard;
  margin-bottom: 150px;
  z-index: 2;

  @media (min-width: $breakpointSmall) {
    margin-bottom: 0px;
  }

  img {
    height: $logoHeight;
  }

  .password-reset-success-page-title {
    position: absolute;
    top: $spacingLarge;
    left: $spacingLarge;
  }

  .password-reset-success-card {
    width: 100%;
    min-height: 465px;
    top: $spacingVeryLarge;

    @media (min-width: $breakpointSmall) {
      width: 600px;
      top: 0px;
    }
  }

  .password-reset-success-description {
    padding-top: $spacingLarge;
    line-height: 19px;
    color: $darkgrey;
    font-size: $fontSizeSM;
    margin: 0px;
  }

  .password-reset-success-button {
    margin-top: $spacingVeryLarge * 2;
    margin-bottom: $spacingVeryLarge;
    width: 200px;
  }
}
