@import "../../index.scss";

.layout-wrapper {
  display: flex;
  padding-top: 72px;
  width: 100%;

  .layout-content {
    width: calc(100% - 250px);

    @media (max-width: $breakpointMedium) {
      width: 100%;
    }
  }
}
