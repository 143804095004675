@import '../../../index.scss';

.components__nested-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid $lightSilver;
  padding-inline: 1rem;
  min-height: 57px;

  .dropdown-toggle-value-default {
    padding-left: 14px;
    padding-right: 7px;
  }
  .input-field-row {
    display: flex;
    flex: 1;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .short-input {
    :nth-child(1) {
      max-width: 35%;
      input {
        text-align: center;
      }
    }
  }

  .chekbox-field-row {
    flex: 1;
  }

  .checkbox-tick {
    background-color: $white;
  }

  .checkbox-unchecked {
    background-color: $white;
    border: unset;
  }

  .--show {
    display: inline;
  }

  .--hide {
    display: none;
  }

  input {
    background-color: transparent;

    &:focus {
      background-color: transparent;
    }
  }

  .disable-inputs {
    label {
      color: $lightGrey !important;
    }

    .components__form-text-input-field .form-inner-wrapper input {
      opacity: 0.5;
      pointer-events: none;
    }

    .nested-question__dropdown-container {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .nested-question__parent-container {
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
    padding-bottom: 12px;

    > label {
      font-weight: $normal;
      margin-top: 1.2rem;
    }

    .components__form-text-input-field .form-inner-wrapper input {
      width: 100% !important;
      min-width: 100% !important;
      height: 20px;
      line-height: 15px;
      margin-top: 0.5rem;
    }

    .components__label {
      margin-bottom: 0.15rem !important;
    }

    .components__form-text-input-field .--number {
      width: 100% !important;
    }

    .components__form-text-input-field .form-text-input-field-number {
      width: 100% !important;
    }
  }

  .components__nested-question-field {
    flex: 1;
  }

  .nested-question__dropdown-container {
    min-width: max-content;
    min-width: 5rem;
  }

  .row-question__dropdown {
    min-width: max-content;
    align-items: center;
    p {
      margin: 0;
      padding-right: 10px;
      font-weight: $normal;
      line-height: 50px;
    }
  }

  @media (max-width: $breakpointMedium) {
    display: block;
    > div {
      margin-top: 1rem;
    }
  }

  .components__form-checkbox-field label {
    margin-top: 0.25rem;
  }
}
