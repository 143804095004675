@import "../../../index.scss";

.internal-back-link {
  background: transparent;
  border: 0;
  font-weight: $bold;
  display: flex;
  align-items: center;
  color: var(--primary);
  margin-left: 0;
  padding: 0;
  margin-bottom: 1rem;
}

.internal-back-link__chevron {
  transform: rotate(90deg);
  height: 20px;
  width: auto;
}
