@import "../../../../index.scss";

.annual-summary__header-container {
  display: flex;
  justify-content: space-between;
}

.annual-summary__titles {
  margin-bottom: $spacingLarge;
}
.annual-summary__dropdown-container {
  .annual-summary__dropdown {
    display: flex;
    align-items: center;
    label {
      margin: 0;
      padding-right: 10px;
      font-weight: $normal;
    }
    .dropdown {
      min-width: 200px;
    }

    .dropdown-menu {
      width: 200px;
    }
  }
}
