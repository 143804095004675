@import "../../../../index.scss";

.page-nav-title {
  position: relative;
  border-radius: 6px;
  overflow: hidden;

  a {
    text-decoration: none;
  }
  &:hover {
    a {
      text-decoration: none;
      color: inherit;
    }
  }
  .panel-container {
    display: flex;
    align-items: center;
    min-height: 100px;
    height: 100%;
    background-color: var(--primary);
    color: $white;
     svg {
      polyline {
        stroke: $white;
      }
    }
  }

  .page-nav-title__content {
    height: 100%;
    width: 100%;
    margin-right: 12px;
     & div:nth-child(1) {
          font-size: $fontSizeSM;
          font-weight: $semiBold;
          letter-spacing: 0;
          margin-bottom: 8px;
        }
      & div:nth-child(2) {
          font:$h6;
          font-size: 20px;
          letter-spacing: -0.34px;
          margin-bottom: 8px;
        }
         & p {
          padding: 0px;
          margin: 0px;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: $formLabelSpacing;
        }
  }

  @media (max-width: $breakpointLarge) {
    .panel-container {
      min-height: 180px;
    }
  }
}

.page-nav--card-img {
    object-fit: cover;
    object-position: center;
    position: absolute;
    opacity: 0.5;
    z-index: 100;
    right: 0px;
    text-align: center;
  }
