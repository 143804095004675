@import "../../../../index.scss";

.circular-progress-bar {
  background: $taupe;
  border-radius: $standardBorderRadius;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  margin-bottom: 10px;
}

.circular-progress-bar-complete {
  position: absolute;
  right: 0;
  width: 35%;
}

.circular-progress-bar__progress {
  margin-bottom: $spacingStandard;

  .circle {
    border-radius: 50%;
    height: auto;
    width: 1005;
    position: relative;
  }
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: var(--primary);
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: var(--washedOutPrimary);
}

.CircularProgressbar .CircularProgressbar-text {
  font: $h5;
  fill: $fontDark;
}
