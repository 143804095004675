@import "../../../index.scss";

.components__footer {
  position: fixed;
  background-color: transparent;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  z-index: 99;

  .footer-wrapper {
    width: 100%;
    height: 100px;
    background-color: $white;
    color: white;
    text-align: center;
    padding: $spacingStandard;
    margin-top: 23px;
  }

  .footer-text {
    font-size: 15px;
    color: $darkgrey;
  }
}
