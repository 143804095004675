@import '../../../index.scss';

.summary-title {
  margin-bottom: $spacingSmall;
  font-size: 19px;
}

.summary-columns {
  margin-bottom: $spacingStandard;
  min-width: 220px;
  .panel-container {
    height: 100%;
  }

  .summary-category-info {
    cursor: pointer;
  }
}

.summary-section-nature {
  .question-tile {
    color: #4ca471 !important;
    border: 2px solid #4ca471 !important;
  }
}

.summary-section-primary {
  .question-tile {
    color: #4ca471 !important;
    border: 2px solid #4ca471 !important;
  }
}

.summary-section-secondary {
  .question-tile {
    color: $mutedBlue !important;
    border: 2px solid $mutedBlue !important;
  }
}

.summary-section-tertiary {
  .question-tile {
    color: #d8740d !important;
    border: 2px solid #d8740d !important;
  }
}

.summary-section-approach {
  .question-tile {
    color: #8a4cf6 !important;
    border: 2px solid #8a4cf6 !important;
  }
}

.summary-section-baseline {
  .question-tile {
    color: #007776 !important;
    border: 2px solid #007776 !important;
  }
}
