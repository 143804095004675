@import '../../../index.scss';

.modal-open > .components__modal {
  display: flex !important;
}

.components__modal {
  align-items: center;
  justify-content: center;
  .modal-content {
    padding: $spacingLarge;
    border-radius: 8px;
    border: 0;
    .modal-container {
      overflow-y: auto;

      hr {
        margin: 1.2rem -1.5rem 1.2rem;
        border: 0;
        border-top: 1px solid #70707050;
      }
    }
  }

  .modal-icon {
    max-width: 200px;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    border: 0;
    padding: 0;
    margin-bottom: $spacingStandard;
  }

  .modal-close-button {
    background: transparent;
    border: 0;
  }

  .modal-title {
    text-align: center;
  }

  .modal-subtitle {
    margin-bottom: $spacingStandard;
  }

  .modal-body-area {
    margin-bottom: $spacingStandard;
  }

  .modal-footer-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button.primary {
      background-color: var(--primary);
      border-color: var(--primary);
    }

    button.primary.--inverse {
      background-color: #fff;
      color: var(--primary);
    }

    button.secondary {
      background-color: $mutedBlue;
      border-color: $mutedBlue;
    }

    button.secondary.--inverse {
      background-color: #fff;
      color: $mutedBlue;
    }

    button.tertiary {
      background-color: #d8740d;
      border-color: #d8740d;
    }

    button.tertiary.--inverse {
      background-color: #fff;
      color: #d8740d;
    }

    button.approach {
      background-color: #8a4cf6;
      border-color: #8a4cf6;
    }

    button.baseline.--inverse {
      background-color: #fff;
      color: #8a4cf6;
    }

    button.baseline {
      background-color: #007776;
      border-color: #007776;
    }

    button.baseline.--inverse {
      background-color: #fff;
      color: #007776;
    }
  }

  .modal-buttons {
    justify-content: center;
    border-top: none;
  }

  @media (min-width: $breakpointMedium) {
    .modal-content {
      min-width: 745px;
    }
  }
}
.components__unsave_modal {
  @extend .components__modal;
  @media (min-width: $breakpointMedium) {
    .modal-dialog {
      min-width: 508px;
    }

    .modal-content {
      min-width: 508px;
    }
  }
}

@media (min-width: $breakpointMedium) {
  .modal-dialog {
    min-width: 745px;
  }
}
