@import "../../../index.scss";

.components__reset_password_form {
  .input-field-row {
    margin-bottom: $spacingStandard;
  }

  .reset-password-button {
    width: 200px;
    position: relative;
    z-index: 2;
  }

  .card-buttons-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: $spacingStandard * 3;
  }

  .forgotten-description {
    color: $darkgrey;
    font-size: $fontSizeSM;
    padding-top: $spacingLarge;
    padding-bottom: $spacingLarge;
  }
}
