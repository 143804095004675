@import "../../../index.scss";

.components__login_form {
  .input-field-row {
    margin-top: $spacingLarge;
    margin-bottom: $spacingStandard;
  }

  .sign-in-button {
    width: 220px;
    margin-top: $spacingStandard;
  }

  .alert {
    margin-top: $spacingStandard;
  }
}
