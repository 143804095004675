@import "../../index.scss";

.component__profile-settings {

  .profile-page-profile-settings-form {
    margin-top: $spacingVeryLarge;
    width: 100%;

    @media (max-width: $breakpointSmall) {
      margin-top: $spacingStandard;
    }
  }
}

