@import "../../../index.scss";

.components__registration_form {
  background-color: $black;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $black inset !important;
    -webkit-text-fill-color: $white;
  }

  form-control,
  input {
    color: $white !important;
    background-color: $black;

    &:focus {
      background-color: $black
    }
  }

  label {
    color: $white;
  }

  .registration-form-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .input-field-row {
    flex: 0.48;
    margin-top: $spacingLarge;
    margin-bottom: $spacingStandard;
  }

  .registration-button {
    width: 220px;
    margin-top: $spacingStandard;
    background-color: primary;
    position: relative;
    z-index: 3;
  }

  .alert {
    margin-top: $spacingStandard;
  }

  .registration-terms {
    color: $white;
    margin: 0px;

    a {
      color: $hyperLink;
    }
  }
}
