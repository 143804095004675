@import "../../../../index.scss";


.improve-menu {
  ul {
    padding-bottom: 0 !important;
  }

  .sidebar-submenu--item {
    border-bottom: 0 !important;
  }
}
.improve-menu::after {
  content: "";
  position: absolute;
  left: 5%;
  height: 1px;
  width: 90%;
  padding-bottom: 0px;
  border-bottom: 1px solid var(--primary);
}

.pro-sidebar .pro-menu .sidebar-nav--selectedItem:after {
  border-bottom: 0 !important;
}

.pro-sidebar .pro-menu {
  .active {
    color: var(--primary);
  }

  .pro-arrow-wrapper {
    width: 20px;
    height: 20px;
    right: 13px;
    top: 15px;
  }
}
