@import '../../../index.scss';

.question-checkbox-wrapper .check-box-input {
  background-color: transparent;
  margin-left: 5px;
}

.data-grid-add-button {
  min-width: 93px;
  border-radius: 22px;
  background-color: $white;
  border-style: unset;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  margin-top: $spacingLarge;
  border: 1px solid $hyperLink;
  align-self: flex-start;
}

.data-grid-add-button-text {
  color: $hyperLink;
  font-size: $fontSizeMD;
  font-weight: $semiBold;
  text-align: left;
  padding: 0px;
  margin: 0px;
  margin-left: $spacingSmall;
}
.data-grid-add-button-text {
  color: #000000;
  font-size: $fontSizeMD;
  font-weight: $semiBold;
  text-align: left;
  padding: 0px;
  margin: 0px;
  margin-left: $spacingSmall;
}
