@import "../../../../index.scss";

.set-title {
  margin-bottom: $spacingReallySmall;
  font-size: 15px;
}

.annual-data-section-separator {
  width: 100%;
  height: 0.5px;
  background-color: $darkgrey;
  margin-top: 1.7em;
  margin-bottom: $spacingStandard;
}
