@import '../../index.scss';

.question-outer-container {
  display: flex;
  justify-content: space-between;
}

.question-guidance-button__container {
  padding-bottom: $spacingStandard;
  border-bottom: 1px solid $lightSilver;
  margin-bottom: $spacingStandard;
}

@media (max-width: $breakpointMedium) {
  .question-guidance-button__container {
    width: 100%;
  }
}

.components__questions-wrapper {
  border-radius: 4px;
  background-color: #f2f2f2;

  .question-inner-container {
    display: flex;
    flex-direction: row;

    @media (max-width: $breakpointMedium) {
      flex-direction: column;
    }
  }

  .question-form-wrapper {
    display: felx;
    flex-direction: row;

    @media (max-width: $breakpointMedium) {
      flex-direction: row;
    }
  }
  .question-wrapper {
    width: 75%;
    @media (max-width: $breakpointMedium) {
      width: 100%;
    }
  }

  .question-guidance-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: $spacingStandard;
    padding-left: $spacingStandard;

    .--hollow-button {
      background: white;
      color: var(--primary);
    }
    .chekbox-field-row {
      margin: $spacingStandard 0;

      > div {
        flex-direction: row-reverse;
        label {
          padding-right: $spacingSmall;
        }
        .checkbox-wrapper {
          background: $white;
        }
        .checkbox-tick {
          margin: 0;
        }
      }
    }

    @media (max-width: $breakpointMedium) {
      width: 100%;
      padding-right: 0px;
      justify-content: center;
    }
  }

  .summaryText {
    color: $black;
    font-size: $fontSizeSM;
    line-height: 19px;
    text-align: left;
  }

  .question-add-button {
    min-width: 93px;
    border-radius: 4px;
    background-color: $white;
    border-style: unset;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    margin-top: $spacingLarge;
    padding: 0.75rem 1rem;
  }
  .question-add-button-text {
    color: $black;
    font-size: $fontSizeMD;
    font-weight: $bold;
    text-align: left;
    padding: 0px;
    margin: 0px;
    margin-left: $spacingSmall;
  }

  .question-guidance-button {
    width: 180px;

    &:hover {
      transition: $transition;
    }

    @media (max-width: $breakpointMedium) {
      width: 100%;
    }
  }
  .--marginTop {
    margin-top: 24px;
  }

  .--white {
    background-color: $white;
  }

  .question-separator {
    height: 1px;
    margin: $spacingStandard 0;
    background-color: $lightSilver;
  }

  .question-add-notes {
    padding: $spacingStandard 0 0 0;
  }

  .guidance-modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
  .guidance_title {
    font-weight: $semiBold;
  }

  .components__label {
    color: $black;
    font-weight: $normal !important;
  }
}

@media (max-width: $breakpointLarge) {
  .question-outer-container {
    flex-direction: column-reverse;
  }

  .components__questions-wrapper .question-wrapper {
    width: 100%;
  }

  .components__nested-question .input-field-row {
    width: 100%;
  }

  .row-question-field-row {
    width: 100%;
  }
}
