@import '../../../index.scss';

.components__form-checkbox-field {
  margin: 0px;
  padding: 0px;
  transition: $transition;

  label {
    margin: 0px;
    padding: 0px;
    font-weight: 400;
    font-size: $fontSizeSM;
    font-family: $main;
  }

  .checkbox-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .checkbox-tick {
    width: 24px;
    height: 24px;
    border: 1px $lightGrey solid;
    border-radius: 4px;
    margin-right: $spacingSmall;
  }

  .checkbox {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-left: 5px;
  }

  .checkbox-checked {
    background-color: var(--primary);
    border-radius: 1px;
  }

  .checkbox-unchecked {
    border-radius: 1px;
  }

  .checkbox-error {
    padding-left: $spacingStandard * 2;
  }

  .error {
    border: 1px $validationFailureBorderColour solid;
  }

  .--rounded {
    display: flex;
    min-height: 44px;
    background-color: white;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding: $spacingSmall $spacingStandard $spacingSmall $spacingStandard;
  }

  .checkbox-rounded-not-checked {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border-style: solid;
    border-color: $lightSilver;
    border-width: 1px;
    background-color: $white;
    margin: 0px $spacingSmall;
    user-select: none;
    cursor: pointer;
  }

  .checkbox-rounded-checked {
    width: 24px;
    height: 24px;
    margin: 0px $spacingSmall;
    user-select: none;
    cursor: pointer;
  }
}

.components__form-checkbox-dark {
  background-color: $black !important;

  label {
    color: $white;
  }

  .checkbox-tick {
    background-color: $black;
  }

  .checkbox-unchecked {
    background-color: $black;
    border: 1px $black solid;
  }

  .light {
    background-color: $white;
    color: $black;
  }
}

.components__form-checkbox-light {
  background-color: $white;

  label {
    color: $black;
  }

  .checkbox-tick {
    background-color: $lightSilver;
  }

  .checkbox-unchecked {
    background-color: $lightSilver;
    border: 1px $lightSilver solid;
  }

  .light {
    background-color: $white;
    color: $black;
  }
}

@media (max-width: $breakpointMedium) {
  .limitLableWidth {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
