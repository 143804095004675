@import "../../index.scss";

$largeGalleryBreakPoint: 1060px;

.component__profile-facilities {
  display: flex;
  flex-direction: column;
  padding-top: $spacingVeryLarge;

  @media (max-width: $breakpointSmall) {
    padding-top: $spacingStandard;
  }

  .gallery-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }

  .gallery-frame {
    display: flex;
    position: relative;
    color: $white;
    margin-right: $spacingStandard;
    margin-bottom: $spacingSmall;
  }

  .gallery-img {
    width: 341px;
    min-height: 225px;
    height: auto;
    object-fit: cover;
    transition: opacity 0.25s ease-in-out;
    background-color: $lightSilver;
    border-radius: 16px;
  }

  .gallery-frame:hover {
    opacity: .7;

  }

  @media(min-width: $breakpointMedium) and (max-width: $largeGalleryBreakPoint){

    .gallery-frame{
    width: 100%;
    margin-right: unset;
    }
  .gallery-img {
     width: 100%;
   }
  }

  @media(max-width: $breakpointMedium){
    .gallery-frame{
      margin-right: unset;
      }

     .gallery-grid{
       justify-content: center;
     }
  }

  .gallery-image-title {
    position: absolute;
    bottom: 0px;
    padding: $spacingSmall;
    color: $white;
    letter-spacing: -0.3;
    font-weight: $bold;
    font-size: $fontSizeLG;
    margin: 0px;
  }

  .gallery-image-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-image: linear-gradient(rgba(0,0,0,0) ,#0000);
  }
}

.components__modal {
  .profile-facility-change-modal-button {
    width: 200px;
    margin-top: $spacingSmall;
    align-self: flex-end;
  }

  .profile-facility-change-modal-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .button__loading-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button__loading-wrapper {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    align-self: center;
  }

  .button__loading-spinner {
    animation-name: spinner;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    .opacity-25 {
      opacity: 0.25;
    }
    .opacity-75 {
      opacity: 0.75;
    }
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}