@import "../../../index.scss";

.components_profile-settings-form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .profile-details-form-title {
    color: $darkgrey;
    font-size: 20px;
    letter-spacing: -0.29px;
    text-align: left;
    font-weight: $semiBold;
    padding-bottom: $spacingSmall;
  }

  .profile-settings-form-wrapper {
    display: flex;
    flex-direction: column;
  }

  .profile-settings-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $breakpointMedium) {
      flex-direction: column;
      width: 100%;
    }
  }

  .profile-settings-card {
    width: calc(50% - 15px);
    min-height: 167px;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 36px;
    padding: 24px;

    @media (max-width: $breakpointMedium) {
      width: 100%;
    }
  }

  .profile-settings-card-title {
    font-size: 20px;
    color: $darkgrey;
    letter-spacing: -0.29;
    line-height: 24px;
    font-weight: $semiBold;
    text-align: left;
    margin: 0px;
    margin-bottom: $spacingStandard;
  }

  .profile-settings-card-description {
    font-size: $fontSizeSM;
    color: $darkgrey;
    letter-spacing: -0.23;
    line-height: 19px;
    text-align: left;
    margin: 0px;
  }

  .input-field-row {
    flex: 0.48;
    margin-top: $spacingLarge;
  }

  .profile-settings-card-notification-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: $spacingSmall;

  }

  .profile-settings-card-separator {
    height: 1px;
    background-color: $lightGrey;
    margin-top: $spacingSmall;
    margin-bottom: $spacingSmall;
  }

  .profile-settings-card-tour-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: $spacingStandard;
  }

  .--disabled {
    display: none;
  }
}