@import '../../../index.scss';
.panel-content {
  display: flex;
}

.annual-review__cards {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: $breakpointMedium) {
    justify-content: space-around;

    & .annual-review__card-container {
      max-width: 45% !important;
    }
  }

  @media (max-width: 1453px) {
    & .annual-review__card-container {
      max-width: 140px;
    }
  }
  @media (max-width: 1273px) {
    & .annual-review__card-container {
      max-width: 130px;
    }
  }
}

.annual-review__card-container {
  max-width: 175px;
  margin: 10px;
}

.panel-button {
  justify-content: flex-end;
  display: flex;
  button {
    min-width: 110px;
  }
}

.annual-review__panel-title {
  margin-bottom: $spacingStandard;
}

.annual-review__panel-description {
  font: $body;
  letter-spacing: $bodyLetterSpacing;
}
