@import "../../main.scss";

.terms-and-conditions__page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacingLarge;
  min-width: 100%;
  min-height: 100vh;
  margin-top: $spacingVeryLarge * 2;
  margin-bottom: $spacingVeryLarge * 2;

  img {
    height: $logoHeight;
  }
}

.terms-and-conditions-no-margin {
  margin-top: 0;
  margin-bottom: 0;
}

.logo-title {
  position: absolute;
  top: $spacingLarge;
  left: $spacingLarge;
}
