@import "../../../index.scss";

.dashboard-row__padding {
    padding-left: 3em;
}
.row-container {
    margin-top: 3em;
    justify-content: space-between;

    & .panel-container  {
        & .page-nav-title__content {
            width: 100%;
            margin-right: 12px;
            & div:nth-child(1) {
                font-size: $fontSizeLG;
                font-weight: $semiBold;
                letter-spacing: 0;
                margin-bottom: 4px;
                line-height: 29px;
                }
            & div:nth-child(2) {
                font-size: $fontSizeMD;
                letter-spacing: $h6LetterSpacing;
                line-height: 26px;
                margin-bottom: 8px;
                }
            & p {
                color: #2B8FC3;
                font-size: 16px;
                letter-spacing: -0.23px;
                line-height: 19px;
            }
        }
        & .dashboard-title-button__wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 12px;
        }
        }
}

.dashboard-no-org-tiles-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 3em;

  @media (max-width: $breakpointLarge) {
    flex-direction: column;
    }
  }

  .dashboard-no-org-tile {
    flex: 1;
    border-radius: 8px;
    background-color: var(--primary);
    margin-right: 3em;

    & .panel-container {
    box-shadow: none;
    }

    &:last-of-type {
    margin-right: 0;
    }

    @media (max-width: $breakpointLarge) {
      margin-right: 0;
      margin-bottom: $spacingLarge;
    }
  }
  .dashboard-transparent-tile {
    background-color: transparent;
     @media (max-width: $breakpointLarge) {
      margin-right: 0;
      margin-bottom: -2.5em;
    }
  }

  .dashboard-number {
    right: -50px;
    overflow: hidden;
  }
