.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.login-form-wrapper {
  width: 500px;
}
